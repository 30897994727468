import { useEffect, useState } from 'react'
import { getToken } from '../utils/tokentUtils';
import axios from 'axios';
import { showFailedToastAlert } from '../utils/toastAlerts';
import { apiBaseUrl } from '../utils/urls';

const usePostData = (link,data) => {

  const [postData,setPostData] = useState<any>(null)
  const token = getToken()

  useEffect(() => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiBaseUrl}${link}`,
      headers: {
        Authorization:`Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            setPostData(data?.data.data);
          } else {
            showFailedToastAlert(
              `Getting error from ${link?.slice(34)} ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log( `Getting error from ${link?.slice(34)} ${error?.message}`);
        });

  } catch (error) {
  }        
}, [link,data]);


  return {postData}
}

export default usePostData
