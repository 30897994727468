import { useEffect, useState } from "react";
import { TfiSave } from "react-icons/tfi";

function CuttingSystem({ customerPlans, formDetail, handleFormSubmit }) {
  //state for cutting system
  const [cuttingForm, setCuttingForm] = useState({
    cutting_value: "",
    cutting_unit: "",
    cutting_logic: "",
  });

  //as we need to set initial value of state  with api data we use useeffect()
  useEffect(() => {
    setCuttingForm((prev) => ({
      ...prev,
      cutting_value: customerPlans?.options?.cutting_value,
      cutting_unit: customerPlans?.options?.cutting_unit,
      cutting_logic: customerPlans?.options?.cutting_logic,
    }));
  }, [customerPlans]);

  //onchange function for cutting system
  const onChangeSelectBox2 = (e, name) => {
    setCuttingForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  //onchange function for cutting state
  const onChangeFun3 = (e, name) => {
    setCuttingForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  return (
    <>
      <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] w-[100%] ">
        <div className="mb-4 w-[100%] mt-6">
          <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
            The configuration setting below allows you to set a limit on sms
            cutting system. For example, to set number of cutting sms to{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              5 percent sms randomly{" "}
            </span>
            , you can set{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              Cutting Value= 5, Cutting Unit = Percentage, and Cutting Login =
              Cutting Random Number
            </span>{" "}
            accordingly. Example If you want to send{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              100 sms
            </span>{" "}
            and set cutting value{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              5 percent
            </span>{" "}
            then{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              95 sms
            </span>{" "}
            will send, rest{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              5 sms
            </span>{" "}
            set delivered as status without sending. You can also set{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              specific amount
            </span>{" "}
            of cutting sms using
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              Digit
            </span>{" "}
            instead{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              Percentage{" "}
            </span>
            from{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              Cutting Unit
            </span>{" "}
            dropdown. Set{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
              0
            </span>{" "}
            for no cutting.
          </p>
        </div>
        <form
          onSubmit={(e) =>
            handleFormSubmit(
              e,
              formDetail.formCutting.url,
              cuttingForm,
              formDetail.formCutting._method,
              formDetail.formCutting._token
            )
          }
        >
          <div className="width-[100%] flex flex-col  mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Cutting Value<span className="text-red-600"> *</span>
            </label>
            <input
              type="number"
              className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%] md:w-[50%]"
              value={cuttingForm?.cutting_value}
              onChange={(e) => onChangeFun3(e, "cutting_value")}
              required
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 mt-4">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Cutting Unit<span className="text-red-600"> *</span>
            </label>
            <select
              name=""
              id=""
              value={cuttingForm?.cutting_unit}
              onChange={(e) => onChangeSelectBox2(e, "cutting_unit")}
              className="rounded-md px-[1rem] text-[--accent-gray] md:w-[50%] w-[100%] outline-[--accent-orange] text-sm  border border-grey-500 h-[40px]"
            >
              {["percentage", "digit"].map((val) => (
                <option
                  value={val}
                  selected={cuttingForm?.cutting_unit === val ? true : false}
                >
                  {val.charAt(0).toUpperCase() + val.slice(1)}
                </option>
              ))}
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Cutting Logic<span className="text-red-600"> *</span>
            </label>
            <select
              name=""
              id=""
              value={cuttingForm?.cutting_logic}
              onChange={(e) => onChangeSelectBox2(e, "cutting_logic")}
              className="rounded-md px-[1rem] text-[--accent-gray] md:w-[50%] w-[100%] outline-[--accent-orange] text-sm  border border-grey-500 h-[40px]"
            >
              <option
                value="random"
                selected={
                  cuttingForm?.cutting_logic === "random" ? true : false
                }
              >
                Cutting Random Number
              </option>
              <option
                value="start"
                selected={cuttingForm?.cutting_logic === "start" ? true : false}
              >
                Cutting Number From Starting
              </option>
              <option
                value="end"
                selected={cuttingForm?.cutting_logic === "end" ? true : false}
              >
                Cutting Number From Ending
              </option>
            </select>
          </div>
          <div className="mt-4 mb-6 mx-4">
            <button
              type="submit"
              className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center "
              onClick={() => console.log(cuttingForm)}
            >
              <TfiSave className="mr-1" />
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CuttingSystem;
