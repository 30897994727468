import { useState, useEffect } from "react";
import { getToken } from "../../utils/tokentUtils";
import axios from "axios";
import DialogBox from "../DialogBox";
import { Switch } from "antd";
import FormSkeleton from "../FormSkeleton";
import DataTable from "react-data-table-component";
import HandlingPagination from "../HandlingPagination";
import { TfiSave } from "react-icons/tfi";
import { showFailedToastAlert } from "../../utils/toastAlerts";

const Notifications = () => {
  const token = getToken();
  let postData = {
    length: 10,
    start: 0,
    orderBy: "message",
    orderDir: "asc",
    search: "",
  };

  const [searchinput, setSearchInput] = useState<any>("");
  const [notifications, setNotifications] = useState<any>(null);


  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "message",
        orderDir: "asc",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://smsvendor.com/api/v1/users/account/notifications",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          const data = response?.data;
          console.log(data);
          if (data?.status === "success") {
            console.log(data);
            setNotifications(data?.data.data);
          } else {
            showFailedToastAlert(
              `Getting Customers Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

  console.log(notifications);


  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue: any) => {
    setTable(paginatedValue);
  };

  const data = dataForTable?.map((val: any) => ({
    id: val.uid,
    message: val.message,
    mark_read: val.mark_read,
    notification_type: val.notifications_type,
  }));

  const columns: any = [
    {
      name: "TYPE",
      selector: (row: any) => (
        <>
          <div className="flex gap-[10px] cursor-pointer">
            {" "}
            <div>
              <p className="text-[14px] text-[#5F6368]">
                {row.notification_type}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "MESSAGE",
      selector: (row) => row.message,
    },
    {
      name: "MARK AS READ",
      selector: (row) => <Switch checked={row.mark_read} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => (
        <div className="flex gap-4 ">
          <DialogBox title="delete" />
        </div>
      ),
    },
  ];

  return (
    <>
          <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
            {/* -------form part 3----- */}
            <div className="bottom  p-2">
              <div className="bg-white ">
                {/* <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]">
                  <div className="flex items-center">
                    <button className="accent-btn" >Actions</button>
                  </div>
                </div> */}
                <div className="  items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] ">
                  <div className="flex items-center">
                    <button className="accent-btn" >Actions</button>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
                  <div className="flex items-center gap-[8px]">
                    <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
                      Show
                    </p>
                    <select
                      name="num"
                      id="num"
                      className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
                    >
                      <option value="10">10</option>
                      <option value="9">9</option>
                      <option value="8">8</option>
                      <option value="7">7</option>
                    </select>
                    <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
                      Entries
                    </p>
                  </div>
                  <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
                    <input
                      onChange={(e) => setSearchInput(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
                    />
                  </div>
                </div>

                {notifications === null ? (
                  <FormSkeleton
                    titles={["Name", "Current Plan", "Status", "Action"]}
                  />
                ) : (
                  <DataTable
                    columns={columns}
                    data={dataForTable}
                    selectableRows
                    className="px-[10px] h-[550px]"
                  />
                )}

                <HandlingPagination
                  data={notifications}
                  getDataForTable={getDataForTable}
                />
              </div>
              <div className="flex justify-end mt-4">
                <button className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center">
                  <TfiSave />
                  save changes
                </button>
              </div>
            </div>
          </div>
       
    
    </>
  );
};

export default Notifications;
