import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  loginData: {},
  avatar: "",
  token: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setReduxUser: (state, action) => {
      state.user = action.payload;
    },
    setLoginData: (state, action) => {
      state.loginData = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setReduxUser, setLoginData, setAvatar, setToken } =
  userSlice.actions;

export default userSlice.reducer;
