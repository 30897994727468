import  {useState,useEffect} from 'react'
import {TfiSave} from "react-icons/tfi"
import axios from 'axios'
import { getToken } from '../utils/tokentUtils'
import { showFailedToastAlert } from '../utils/toastAlerts'


const CreateRole = () => {
  
  const token = getToken();
  const [roleData,setRoleData] = useState<any>([]);
  
  

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://smsvendor.com/api/v1/admin/roles/create`,
    headers: {
      Authorization:`Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  //function for get the data for role
  const apicall = ()=>{
    axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          console.log(data)
          if (data?.status === "success") {
            setRoleData(data.data)
          } else {
            showFailedToastAlert(
              `Getting role error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting roles Number error ${error?.message}`);
        });
  }


  useEffect(()=>{
    apicall();
  },[])



  return (
    <>  
   

          {/* edit admin role  section started */}
          <div className='top-bottom-container rounded-md shadow-xl bg-white p-4 my-6 md:w-[60%] w-[100%] font-sans'>
          <h2 className="text-md font-[500] text-[--accent-gray] mx-2 my-4">Create Role</h2>
          <div className="width-[100%] mt-2 flex flex-col mb-[1rem] mx-2 ">
          <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Name<span className="text-red-600"> *</span>
            </label>
              <input
                type="text"
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%]  "
                value=""
                placeholder='Required'
              />
            </div>
            <div className='flex gap-2 ml-2 my-6'>
                            <input className='accent-[#F08B38] bg-white  cursor-pointer h-5 w-4 ' type="checkbox" name="" id="" />
                            <label className='text-[#5f6368] text-[14px] font-thin' htmlFor="">SELECT ALL</label>
                          </div>  
              {/* -------form part----- */}
              <div className='bottom  p-2'>
                  <form action="" className='' >
                    {
                      roleData?.permissions?.map((value,key)=>(
                        <div key={key} className='mb-[2rem]'>
                          <div className='flex items-center mb-4 w-full'>
                            <h3 className='mr-2 text-[#8E85F3] font-[600]'>{value?.title}</h3>
                            <div className='h-[1px] flex-1 bg-[#17D3EA]'></div>
                          </div>
                           
                          <div className='flex flex-wrap gap-2'>
                         { value?.permissions?.map((val)=>(    
                           <div className='flex mt-2 gap-2'>
                            <input className='accent-[#F08B38] bg-white  cursor-pointer h-5 w-4 '
                             type="checkbox" 
                             checked
                             name="" id="" />
                            <label className='text-[#5f6368] text-[14px] font-thin' htmlFor="">{val.display_name.toUpperCase()}</label>
                          </div>       
                         ))} 
                           </div>   
                      
                      </div>
                      ))
                    }
                      
                  </form>
                  <div className='flex justify-start mt-4'>
                    <button className='bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center'><TfiSave/>Save</button>
                  </div>
              </div>
          </div>



    </>
  )
}

export default CreateRole