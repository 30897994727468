import React from "react";
import { BiSave } from "react-icons/bi";

const CronJobsSection = () => {
  return (
    <>
      {/* <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Numbers
          </span> */}
      <div className="w-[100%] mt-[2rem]  px-4">
        <form action="">
          <div className="">
            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] leading-[1.5rem] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                If you want to run{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  cron jobs or Background jobs
                </span>{" "}
                on{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  cPanel
                </span>{" "}
                based applications then please copy the following commands
                without
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  * * * * *
                </span>{" "}
                and login with your cPanel and search Cron Jobs in the search
                field on the top. Then click on the{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Cron Jobs
                </span>{" "}
                option and select the{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Every minutes
                </span>{" "}
                option from the{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  common setting
                </span>{" "}
                dropdown. For{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Plesk
                </span>{" "}
                based hosting please select{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Scheduled Tasks
                </span>{" "}
                from the right side and click on{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Add Task
                </span>{" "}
                button. Then select{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Run a PHP script
                </span>
                option and open file folder. After that go ultimate SMS
                installation folder and select{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  artisan
                </span>{" "}
                file. Then insert
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  scheduled:run
                </span>{" "}
                on arguments field. Finally, select your PHP version,{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  cron style
                </span>
                from the Run dropdown and insert{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  * * * * *
                </span>{" "}
                For{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Cloud or VPS
                </span>
                based hosting open your Terminal or Bash and Type{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  crontab-e
                </span>{" "}
                for{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  ubuntu or Debian
                </span>{" "}
                and Type{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  sudo nano /etc/crontab
                </span>{" "}
                for{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Centos
                </span>
                operating system. Then insert the following command on the
                Crontab file.
              </label>
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <div className="flex gap-2 ">
                <input type="radio" name="" id="" />
                <label
                  className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                  htmlFor=""
                >
                  /usr/bin/php
                </label>
              </div>
              <div className="flex gap-2 ">
                <input type="radio" name="" id="" />
                <label
                  className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                  htmlFor=""
                >
                  /usr/local/bin/php
                </label>
              </div>
              <div className="flex gap-2 ">
                <input type="radio" name="" id="" />
                <label
                  className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                  htmlFor=""
                >
                  /bin/php
                </label>
              </div>
              <div className="flex gap-2 ">
                <input type="radio" name="" id="" />
                <label
                  className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                  htmlFor=""
                >
                  /usr/lib64/php
                </label>
              </div>
            </div>

            <div className="">
              <div className="flex items-center mb-2 justify-evenly  ">
                <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
                <label className="flex-1 text-center w-full text-[#7367F0] text-[13.125px] mb-[0.2875] ">
                  Background Jobs
                </label>
                <div className=" flex-1 w-full bg-[var(--accent-orange)] h-[1px]"></div>
              </div>
              <p className="text-[var(--accent-gray)]  form-label-size mb-[0.2875]">
                Insert the following line to your system's contab.Please note,
                below timings for running the cron jobs are the recommended, you
                can change it if you want.
              </p>
              <div className=" gap-2 my-6   bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400] w-full h-[50px]">
                <p></p>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button className=" hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn ">
              <BiSave />
              save
            </button>
          </div>
        </form>

        {/* ==================cronjobs-form-6 ended================= */}
      </div>
    </>
  );
};

export default CronJobsSection;
