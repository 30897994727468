import { useState, useEffect } from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { TfiSave } from "react-icons/tfi";

const Security = () => {
  const [showPassword, setShowPassword] = useState<any>(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState<any>(true);

  return (
    <>
      <div className="p-2">
        <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
          {/* -------form part 2----- */}
          <div className="bottom my-4  p-2">
            <form action="" className="flex gap-4">
              <div className="left w-[50%]">
                <div className="flex flex-col gap-5  mb-[16px]  ">
                  <div className="relative gap-1 flex flex-col">
                    <label
                      className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                      htmlFor=""
                    >
                      Current Password
                      <span className="text-red-600"> *</span>
                    </label>
                    <input
                      // value={form1.pwd}
                      type="password"
                      className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
                      name="pwd"
                      // onChange={handleForm1Change}
                    />
                    {showPassword ? (
                      <HiOutlineEye
                        className="absolute text-[var(--accent-gray)] cursor-pointer first-letter: right-[15px] top-[55%] "
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <HiOutlineEyeOff
                        className="absolute text-[var(--accent-gray)] cursor-pointer right-[15px] top-[55%] "
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                  <div className="relative gap-1 flex flex-col">
                    <label
                      className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                      htmlFor=""
                    >
                      New Password
                      <span className="text-red-600"> *</span>
                    </label>
                    <input
                      // value={form1.pwd}
                      type={showPassword ? "password" : "text"}
                      className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
                      name="pwd"
                      // onChange={handleForm1Change}
                    />
                    {showPassword ? (
                      <HiOutlineEye
                        className="absolute text-[var(--accent-gray)] cursor-pointer first-letter: right-[15px] top-[55%] "
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <HiOutlineEyeOff
                        className="absolute text-[var(--accent-gray)] cursor-pointer right-[15px] top-[55%] "
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                  <div className="relative gap-1  flex flex-col">
                    <label
                      className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                      htmlFor=""
                    >
                      Confirm new Password
                      <span className="text-red-600"> *</span>
                    </label>
                    <input
                      // value={form1.pwd}
                      type={showPassword ? "password" : "text"}
                      className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
                      name="pwd"
                      // onChange={handleForm1Change}
                    />
                    {showPassword ? (
                      <HiOutlineEye
                        className="absolute text-[var(--accent-gray)] cursor-pointer first-letter: right-[15px] top-[55%] "
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <HiOutlineEyeOff
                        className="absolute text-[var(--accent-gray)] cursor-pointer right-[15px] top-[55%] "
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="flex justify-end mt-4">
              <button
                // onClick={handleForm2Submit}
                className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
              >
                <TfiSave />
                save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
