import { useState} from "react";
import { VscAccount } from "react-icons/vsc";
import { BiInfoCircle } from "react-icons/bi";
import { FiLock } from "react-icons/fi";
import { BiBookmark } from "react-icons/bi";
import Information from "./EditCustomer/Information";
import Account from "./EditCustomer/Account";
import Permission from "./EditCustomer/Permission";
import Subscription from "./EditCustomer/Subscription";

const EditCustomer = () => {

  // for showing diffrent forms like accounts, permission, subscription etc
  const [show, setshow] = useState<any>("account");

  // function that handles the navigation click of editcustomer page.
  const handleNavClick = (name: string) => {
    setshow(name); // turn on the selected dropdown and turn off others
  };

  return (
    <>
      <div className="p-2">
        <div className="flex py-4 gap-4 flex-wrap">
          <button
            onClick={() => handleNavClick("account")}
            className={`${
              show === "account"
                ? "bg-[#F08B38] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <VscAccount />
            Account
          </button>
          <button
            onClick={() => handleNavClick("information")}
            className={`${
              show === "information"
                ? "bg-[#F08B38] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <BiInfoCircle />
            Information
          </button>
          <button
            onClick={() => handleNavClick("permission")}
            className={`${
              show === "permission"
                ? "bg-[#F08B38] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <FiLock />
            Permissions
          </button>
          <button
            onClick={() => handleNavClick("subscription")}
            className={`${
              show === "subscription"
                ? "bg-[#F08B38] text-white"
                : "bg-transparent text-black"
            } px-5 py-2 rounded-md  flex gap-1 items-center shadow-lg `}
          >
            <BiBookmark />
            Subscriptions
          </button>
        </div>
        {/* account sections started */}
        {show === "account" && ( <Account />)}
        {/* account sections ended */}

        {/* information  section started */}
        {show === "information" && (<Information />)}

        {/* information section ended */}

        {/* permission  section started */}
        {show === "permission" && (<Permission />)}

        {/*permission section ended */}

        {/* subscription  section started */}
        {show === "subscription" && (<Subscription />)}
        {/* subscription section ended */}
      </div>
    </>
  );
};

export default EditCustomer;
