import { Switch } from "antd";
import { TfiSave } from "react-icons/tfi";

function EditSendingServerNSES() {
  return (
    <div className="main  my-[15px] font-sans flex md:flex-row flex-col gap-4 w-[100%]">
     
      <div className="flex flex-col w-[100%]   gap-8">
         {/*update credential cont*/}
        <div className="bg-[white] shadow-md rounded-md md:w-[60%] w-[100%]">
          <h3 className="text-[--accent-gray] mx-4 my-4">Update Credentials</h3>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Name<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%] "
              value="Sul SMS"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              API Link<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
              value="https://sms.nityashree.com/api/send/sms"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Auth Token<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
              value="FFCJk0GXaKivjI1fm0vGxAD2coV9xfKM19xR2sLUX1wiX3O4mga8QmDmgsnT"
            />
          </div>
        </div>
        {/*sending limit cont*/}
        <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] md:w-[60%] w-[100%]">
          <div className="mb-4">
            <h3 className="text-[--accent-gray] mx-4 my-4 ">Sending Credit</h3>
            <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
              The configuration setting below allows you to set a limit on sms
              sending speed. For example, to limit sending speed to  <span className="rounded-sm bg-[#e6e6e6] text-pink-400">500 sms
              every 5 minutes</span> , you can set <span className="rounded-sm bg-[#e6e6e6] text-pink-400">Sending limit = 500, Time value = 5,
              and Time unit = </span> accordingly. Also set  <span className="rounded-sm bg-[#e6e6e6] text-pink-400">amount</span> of <span className="rounded-sm bg-[#e6e6e6] text-pink-400">several
              messages</span> with a <span className="rounded-sm bg-[#e6e6e6] text-pink-400">single HTTP request.</span>
              
            </p>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Sending Credit<span className="text-red-600"> *</span>
            </label>
            <input
              type="number"
              className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              value="600"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Time Base<span className="text-red-600"> *</span>
            </label>
            <input
              type="number"
              className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              value="1"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Time Unit<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%]"
              name=""
              id=""
            >
              <option value="">Minute</option>
              <option value="">Hour</option>
              <option value="">Day</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              SMS Per Single Unit<span className="text-red-600"> *</span>
            </label>
            <input
              type="number"
              className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              value="1"
            />
          </div>
        </div>
        {/*Availale Feature Container started*/}
        <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] md:w-[60%] w-[100%]">
        <h3 className="text-[--accent-gray] mx-4 my-4">Available Features</h3>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
<div className="flex  gap-6">
<div className="flex flex-col gap-3">
<label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Plain<span className="text-red-600"> *</span>
            </label>
            <Switch className="text-[--accent-orange]"/>
</div>
<div className="flex flex-col gap-3">
<label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Schedule<span className="text-red-600"> *</span>
            </label>
            <Switch className="text-[--accent-orange]"/>
</div>
</div>

<button className=" bg-[#F08B38] mt-6 text-white px-[21px] py-[11px] text-sm mr-4 rounded-md flex h-[40px]  w-[80px] gap-1 items-center align-center float-right">
            <TfiSave size={20}/>
            <span className="pl-1">Save</span>
          </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSendingServerNSES;
