import { useState, useEffect } from "react";
import { TfiSave } from "react-icons/tfi";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";
import axios from "axios";
import { apiBaseUrl } from "../utils/urls";

function CreateLanguage() {
  const [languages, setLanguages] = useState<any>(null);
  const [countries, setCountries] = useState<any>(null);

  const token = getToken();
  useEffect(() => {
    try {

          //for languages
          let data1 = JSON.stringify({
            length: 232,
            start: 0,
            orderBy: "name", 
            orderDir: "asc",
            search: ""
    
          });
       //for language get 
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://smsvendor.com/api/v1/admin/languages",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data:data1

      };


      axios
      .request(config)
      .then((response) => {
        //   console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          console.log(data.data.data);
          setLanguages(data?.data);
        } else {
          showFailedToastAlert(
            `Getting Languages Number error ${data?.message}`
          );
        }
      })
      .catch((error) => {
        console.log(`Getting Languages Number error ${error?.message}`);
      });
  } catch (error) {}

      //for countries post
      let data2 = JSON.stringify({
        name: "required|unique:countries,name",
        iso_code: "required|min:2|max:2|unique:countries,iso_code",
        country_code: "required|min:1|max:3",
        status: "required|boolean",
        
        length: 232,
        start: 0,
        orderBy: "name", 
        orderDir: "asc",
        search: ""

      });


  let config2 = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://smsvendor.com/api/v1/admin/countries/search",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data2,
  };
  axios
  .request(config2)
  .then((response) => {
    //   console.log(response.data);
    const data = response?.data;
    if (data?.status === "success") {
      console.log(data.data.data);
      setCountries(data?.data.data);
    } else {
      showFailedToastAlert(
        `Getting Countries Number error ${data?.message}`
      );
    }
  })
  .catch((error) => {
    console.log(`Getting Countries Number error ${error?.message}`);
  });

  }, [token]);


     //state of language form
     const [form, setForm] = useState({
      _token:"qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
   language:"",
   country:"",
   status:"1"
    });
  
    //as we need to set initial value of state  with api data we use useeffect()
    useEffect(() => {
      if (countries && languages) {
      setForm((prev) => ({
        ...prev,
        language: languages[0]?.code,
        country:countries[0]?.iso_code}));
    }
    }, [countries,languages])
  
    //onchange function for selectbox of language
    const onChangeSelectBox= (e, name) => {
      setForm((prev) => {
        return {
          ...prev,
          [name]: e.target.value,
        };
      });
    };
  
      //handle on submit form of language
      const handleFormSubmit = async (e,data) => {
        e.preventDefault();
        try {
          const response = await axios.post(
            `${apiBaseUrl}/admin/languages`,
             {...data},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type":'application/x-www-form-urlencoded'
              },
            }
          );
    
          showSuccessfulToastAlert(response?.data.message);
        } catch (error) {
          showFailedToastAlert(error);
        }
      };

  return (
    <div className="main  my-[15px] font-sans  w-[100%]">
            <form onSubmit={(e)=>{handleFormSubmit(e,form); console.log(form)}}>
      <div className="left flex flex-col w-[100%] md:w-[50%]   gap-4">
        <div className="bg-[white] shadow-md rounded-md w-[100%]">
          <h3 className="text-md font-[500] text-[--accent-gray] mx-4 my-4">
            Add New Language
          </h3>

       
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Language<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id="-"
              value={form.language}
              onChange={(e)=>onChangeSelectBox(e,'language')}
            >
              {languages?.map((val,key)=>(
                <option value={val?.code}>{val?.name}</option>
              ))}
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Country<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
              value={form.country}
              onChange={(e)=>onChangeSelectBox(e,'country')}

            >
              {countries?.map((val,key)=>(
                <option value={val?.iso_code}>{val?.name}</option>
              ))}
            </select>
          </div>
          

          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Status<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
              value={form.status}
              onChange={(e)=>onChangeSelectBox(e,'status')}

            >
    
                <option value='1'>Active</option>
                <option value='0'>Disable</option>

            </select>
          </div>
          <div className="mt-4 mb-6 ml-4">
        <button type='submit' className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center ">
          <TfiSave className="mr-1" />
          Save
        </button>
      </div>
        </div>
      
 
      </div>
</form>
    </div>
  );
}

export default CreateLanguage;
