import { useEffect, useState } from "react";
import { TfiSave } from "react-icons/tfi";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getToken } from "../utils/tokentUtils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { apiBaseUrl } from "../utils/urls";

function UpdateEmailTemplate() {
  const { template } = useParams();
  const token = getToken();
  const [emailTemplate, setEmailTemplate] = useState<any>([]);
  const [availableTagList, setAvailableTagList] = useState<any>();

  console.log(emailTemplate)

  //function to get list of word from api string which is inside {}
  //this () will take that api string and extract all the words needed
  const tagFun = (stringTagList) => {
    const words: string[] = [];
    if (stringTagList) {
      const regex = /{\S+}/g;
      let match;
      while ((match = regex.exec(stringTagList)) !== null) {
        words.push(match[0]);
      }
    }
    setAvailableTagList(words);
  };

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://smsvendor.com/api/v1/admin/email-templates/${template}/show`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  //function for get the data for single customer
  const apicall = () => {
    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          setEmailTemplate(data.data);
          //passing stringTagList from api to tagFuntion
          tagFun(data.data.content);
        } else {
          showFailedToastAlert(
            `Getting single template error ${data?.message}`
          );
        }
      })
      .catch((error) => {
        console.log(`Getting template Number error ${error?.message}`);
      });
  };

  useEffect(() => {
    apicall();
  }, []);

     //state for emailTemplate
     const [form, setForm] = useState({
      _method:"PUT",
      _token:"qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
      subject:"",
    });
  
    //as we need to set initial value of state  with api data we use useeffect()
   useEffect(() => {
    setForm((prev) => ({
      ...prev,
      subject: emailTemplate?.subject,    }));
      setEditorValue(emailTemplate?.content)
  }, [emailTemplate]);
  
    //onchange function for form state
    const onChangeFun = (e, name) => {
      setForm((prev) => {
        return {
          ...prev,
          [name]: e.target.value,
        };
      });
    };

  //state for content reactQuill
  const [editorValue, setEditorValue] = useState('');

    //onchange function for reactQuill content state
    const handleEditorChange = (value) => {
      setEditorValue(value);
    };

        // handle on submit form of updateEmailTemp
        const handleFormSubmit = async (e,data) => {
          e.preventDefault();
          try {
            const response = await axios.post(
              `${apiBaseUrl}/admin/email-templates/${template}`,
              {...data,content:editorValue},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type":'application/x-www-form-urlencoded'
                },
              }
            );
      
            showSuccessfulToastAlert(response?.data.message);
          } catch (error) {
            showFailedToastAlert(error);
          }
        };
  
  return (
    <>       
     <form action="" onSubmit={(e)=>{handleFormSubmit(e,form)}}>

      {/*Main Container*/}
      <div className="main  my-[15px] font-sans flex md:flex-row flex-col text-[--accent-gray] gap-4 w-[100%]">
        {/*left container*/}
        <div className="bg-white shadow-md rounded-md w-[100%] md:w-[70%] flex flex-col gap-4 min-h-[400px]">
          <h3 className="text-md font-[500]  mx-4 my-4">
            {emailTemplate?.name}
          </h3>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%]  mb-[0.2857rem]">
              Subject<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500    outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%] "
              value={form.subject}
              required
              onChange={(e) =>onChangeFun(e,'subject')}
            />
          </div>
          <div className="width-[100%] flex-col  mb-[1rem] mx-4 ">
            <ReactQuill
              theme="snow"
              value={editorValue}
              onChange={handleEditorChange}
            
            />
          </div>
          <div className="mt-4 mb-6 ml-4">
            <button type="submit" className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center ">
              <TfiSave className="mr-1" />
              Save
            </button>
          </div>
        </div>

        {/*right container*/}
        <div className="bg-white shadow-md rounded-md w-[100%] md:w-[30%] flex flex-col gap-2 min-h-[250px] max-h-[400px]">
          <h3 className="text-md font-[500]  mx-4 my-4">
            Available Tags
          </h3>

          <p className="ml-4 w-[80%] text-sm ">
            Required tags must be in the email template editor.
          </p>

          {availableTagList?.map((tag) => (
            <p className="ml-4">
              <span className="text-[#ec419f] bg-green-100 rounded-sm px-1">
                {tag}
              </span>
              =<span className="text-[#36b126] rounded-sm px-1">Required</span>
            </p>
          ))}
        </div>
      </div>
      </form>

    </>
  );
}

export default UpdateEmailTemplate;
