import { useToken } from "antd/es/theme/internal";
import { useState, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";
import axios from "axios";

const SystemEmailSection = () => {
  const token = useToken();

  // defining type of the systemEmail form data
  interface SystemEmail {
    _token: string;
    driver: string;
    from_email: string;
    from_name: string;
    host: string;
    port: number;
    encryption: string;
    username: string;
    password: string;
  }

  const [systemEmail, setSystemEmail] = useState<SystemEmail>({
    _token: "vfcBtwNED453WPD5m0SBvDAdBOAORG39V65iMUGs",
    driver: "",
    from_email: "",
    from_name: "",
    host: "",
    port: 0,
    encryption: "",
    username: "",
    password: "",
  });

  // to add value when component is first mounted
  // useEffect(() => {
  //   setSystemEmail((prevState) => {
  //     return {
  //       ...prevState,
  //       host: "smtp.mailtrap.io",
  //       port: 2525,
  //       encryption: "tls",
  //     };
  //   });
  // }, []);

  // console.log(systemEmail);

  // handles onchange function for all inputs
  // const onChangeFun = (e) => {
  //   const { name, value } = e.target;
  //   setSystemEmail((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: value,
  //     };
  //   });
  // };
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    if (systemEmail.driver === "SMTP") {
      setSystemEmail((prevState) => {
        return {
          ...prevState,
          [name]: value,
          host: "smtp.mailtrap.io",
          port: 2525,
          encryption: "tls",
        };
      });
    } else {
      setSystemEmail((prevState) => {
        return {
          ...prevState,
          [name]: value,
          host: "",
          port: 0,
          encryption: "",
        };
      });
    }
  };




  // API is not working properly
  // --sending error of unauthenticated while post method request

  // handling systemEmail-form-submit ---- information section
  // const handleSystemEmailFormSubmit = async (e) => {
  //   e.preventDefault();

  //   // const formData = new URLSearchParams();
  //   // Object.entries(systemEmail).forEach(([key, value]) => {
  //   //   formData.append(key, value);
  //   // });

  //   try {
  //     const response = await axios.post(
  //       `https://smsvendor.com/api/v1/admin/settings/email`,
  //       // formData.toString(),
  //       { ...systemEmail },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/x-www-form-urlencoded"
  //         },
  //       }
  //     );

  //     console.log(response.data);

  //     showSuccessfulToastAlert(response?.data.message);
  //   } catch (error) {
  //     showFailedToastAlert(error);
  //   }
  // };

  useEffect(() => {
   setSystemEmail((prevState)=>{
    return{
      ...prevState,
      driver:'Sendmail'
    }
   })
  }, [])
  

  const handleSystemEmailFormSubmit = (e) => {
    e.preventDefault();
    console.log(systemEmail);
  };





  return (
    <>
      <div className="w-[100%] sm:w-[100%] md:w-[80%] lg:w-[60%] mt-[2rem]  px-4">
        <form action="">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="left-container w-[100%] sm:w-[100%] md:w-[50%]">
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                  htmlFor="driver"
                >
                  Method for sending system mail
                  <span className="text-red-600"> *</span>
                </label>
                <select
                  id="driver"
                  name="driver"
                  onChange={onChangeFun}
                  
                  required
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
                >
                  
                  <option value="Sendmail">Sendmail</option>
                  <option value="SMTP">SMTP</option>
                </select>
              </div>

              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                  htmlFor="from_email"
                >
                  From Email
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  id="from_email"
                  name="from_email"
                  onChange={onChangeFun}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                  type="text"
                  required
                />
              </div>
            </div>

            <div className="right-container w-[100%] md:w-[50%]  flex items-end ">
              <div className=" flex gap-2 w-full mb-6 flex-col">
                <label
                  className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                  htmlFor="from_name"
                >
                  From Name
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  id="from_name"
                  name="from_name"
                  onChange={onChangeFun}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                  type="text"
                  required
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleSystemEmailFormSubmit}
              className="hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn "
            >
              <BiSave />
              save
            </button>
          </div>
        </form>

        {/* ==================system-form-2 ended================= */}
      </div>
    </>
  );
};

export default SystemEmailSection;
