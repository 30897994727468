import React, { useState } from "react";
import logo from "../assets/biglogo.png";
import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoginData, setToken } from "../redux/userSlice";
import Register from "../components/register";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";

const Login = () => {
  const navigate = useNavigate();
  const [register, setRegister] = useState(false);
  const [Check, setCheck] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const value = e.target.value;

    setLogin({ ...login, [e.target.name]: value });
  };

  const LoginForm = async (e: any) => {
    e.preventDefault();
    console.log(login);
    // Logging in
    var data = JSON.stringify({
      email: login?.email,
      password: login?.password,
      remember: login?.remember,
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://smsvendor.com/api/v1/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          dispatch(setLoginData(data));
          dispatch(setToken(data?.data?.api_token));
          // // console.log(data?.data?.api_token);
          localStorage.setItem("token", data?.data?.api_token);
          navigate("/admin");
          // }
          showSuccessfulToastAlert("Login Successfull!");
        } else {
          showFailedToastAlert("Failed");
        }
      })
      .catch(function (error) {
        showFailedToastAlert("Login Failed!");
        console.log(error);
      });
  };

  return (
    <div className=" bg-[#F9F9F9]">
      <div className="grid md:grid-cols-2 grid-cols-1 h-screen   md:py-[134px]">
        <div className="flex items-center justify-center border-r-[1px]">
          <img src={logo} alt="" className="md:w-[246px] w-[180px] md:h-[100.55px]" />
        </div>
        {!register ? (
          <div className="  flex items-center md:justify-center justify-start flex-col md:px-[166px] px-[25px] 2xl:px-[186px]  ">
            <div className="w-full">
              <h2 className="text-[#0063A5] text-[22px] leading-[28.13px] font-normal text-center">
                Sign In
              </h2>
              <p className=" mt-[41px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
                Please sign-in to your account and start the adventure
              </p>
              <form action="" className="w-full" onSubmit={LoginForm}>
                <div className="w-full mt-[31px]">
                  <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                    Email
                  </p>
                  <input
                    type="email"
                    placeholder="Email address"
                    name="email"
                    className="w-full outline-none border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <div className="flex items-center justify-between mt-[24px] mb-[5px]">
                    <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                      Password
                    </p>
                    <p className="text-[#0072B5] text-[13px] leading-[15.23px] font-normal cursor-pointer">
                      Forgot Password?
                    </p>
                  </div>
                  <input
                    type="password"
                    placeholder="password"
                    name="password"
                    className="w-full outline-none border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex items-center gap-[8px] mt-[24px]">
                  <input
                    type="checkbox"
                    id=""
                    className="w-[16px] h-[16px] cursor-pointer border border-[#808080] outline-none"
                    onChange={(e) =>
                      setLogin({ ...login, remember: e?.target?.checked })
                    }
                  />
                  <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                    Remember Me
                  </p>
                </div>
                <button
                  type="submit"
                  className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] pl-[133px] pr-[134px] mt-[24px] border border-black rounded-lg "
                >
                  Login
                </button>
              </form>
              <p className=" mt-[41px] text-[14px] flex gap-2 justify-center leading-[16.41px] text-[#545454] w-full  font-normal">
                <span>New on Platform</span>
                <div
                  className="text-blue-500 hover:cursor-pointer"
                  onClick={() => setRegister(true)}
                >
                  Register
                </div>
              </p>
            </div>
          </div>
        ) : (
          <Register setRegister={setRegister} />
        )}
      </div>
    </div>
  );
};

export default Login;
