import { Switch } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { TfiSave } from "react-icons/tfi";
import { NavLink, useParams } from "react-router-dom";

function Pricing({ customerPlans, formDetail, handleFormSubmit }) {
  const { plan } = useParams();

  //state for pricing system
  const [pricingForm, setPricingForm] = useState({ per_unit_price: "" });

  //as we need to set initial value of state  with api data we use useeffect()
  useEffect(() => {
    setPricingForm((prev) => ({
      ...prev,
      per_unit_price: customerPlans?.options?.per_unit_price,
    }));
  }, [customerPlans]);

  //onchange function for pricing state
  const onChangeFun4 = (e, name) => {
    setPricingForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  return (
    <>
      <div>
        <div className="ml-4 mt-8 mb-4">
          <NavLink to={`/admin/plans/${plan}/coverage`}>
            <button className=" bg-[--accent-orange]  text-white px-[21px] justify-center items-center text-sm mr-4 rounded-md flex h-[40px]   gap-2 ">
              <span>
                <b>Add Coverage</b>{" "}
              </span>
              <AiOutlinePlusCircle className="mr-1" size={20} />
            </button>
          </NavLink>
        </div>
        <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] w-[100%]  ">
          <div className="mb-4 w-[100%] md:w-[50%] mt-6">
            <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
              You can set the price of your custom unit. Since anyone wants to
              add more units to their purchase plan, you need to set the price
              for each unit. If you set the price at{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                0.3
              </span>{" "}
              USD per unit, it will cost{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                60 USD
              </span>{" "}
              for{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                200 SMS units
              </span>
              . You can set different prices for different customerPlans. Insert
              single unit price here.
            </p>
          </div>
          <form
            onSubmit={(e) =>
              handleFormSubmit(
                e,
                formDetail.formPricing.url,
                pricingForm,
                formDetail.formPricing._method,
                formDetail.formPricing._token
              )
            }
          >
            <div className="width-[100%] flex flex-col  mx-4 ">
              <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                Per unit price<span className="text-red-600"> *</span>
              </label>
              <input
                type="text"
                className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[40px] w-[100%] md:w-[50%]"
                value={pricingForm?.per_unit_price}
                onChange={(e) => onChangeFun4(e, "per_unit_price")}
                required
              />
            </div>
            <div className="mt-4 mb-6 mx-4">
              <button
                type="submit"
                className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center "
              >
                <TfiSave className="mr-1" />
                Save
              </button>
            </div>
          </form>
        </div>

        <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px] bg-[white] shadow-sm rounded-md  space-around-[20px] w-[100%]  ">
          <div className="flex items-center gap-[8px]">
            <p className="text-[--accent-gray] text-[13px] leading-[15.23px] font-normal">
              Show
            </p>
            <select
              name="num"
              id="num"
              className=" rounded-md px-[1rem] text-[--accent-gray] md:w-[50%] w-[100%] outline-[--accent-orange] text-sm  border border-grey-500 h-[40px] cursor-pointer"
            >
              <option value="10">10</option>
              <option value="9">9</option>
              <option value="8">8</option>
              <option value="7">7</option>
            </select>
            <p className="text-[--accent-gray] text-[13px] leading-[15.23px] font-normal">
              Entries
            </p>
          </div>
          <div className="border border-[#D9D9D9] rounded-md flex items-center flex-wrap justify-between">
            <input
              type="text"
              placeholder="Search"
              className="rounded-md text-sm  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px]   "
            />
            <img src=" " alt="" className="cursor-pointer" />
          </div>

          <div className="flex gap-2 flex-col flex-wrap  pl-[37px] mt-[18px] pr-[14px] pb-[21px] bg-[white] shadow-sm rounded-md   w-[100%]">
            <table className="text-[--accent-gray] w-[100%]">
              <thead className="bg-[#f9f9f9] w-[100%] h-[50px] border-b-[1.8px] border-solid border-[#d4cbcb] text-[--accent-gray] font-medium text-sm flex-wrap">
                <tr>
                  <td>Name</td>
                  <td>ISO CODE</td>
                  <td>COUNTRY CODE</td>
                  <td>STATUS</td>
                  <td>ACTIONS</td>
                </tr>
              </thead>
              <tbody>
                <tr className="text-sm">
                  <td>Nepal</td>
                  <td>NP</td>
                  <td>977</td>
                  <td>
                    <Switch className="mt-1" />
                  </td>
                  <td className="flex gap-2 mt-1">
                    <FiEdit
                      className="text-[#35cb78] cursor-pointer"
                      size={15}
                    />
                    <AiOutlineDelete
                      className="text-[#EA5455] cursor-pointer "
                      size={15}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <div className="mt-8 opacity-40">
                      Showing 1 to 1 of 1 entries
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <div className="bg-[#f5f0f0] flex justify-center items-center mt-8 rounded-xl h-[30px] gap-4">
                      <button>
                        <MdNavigateBefore className="bg-[#f9f9f9] cursor-pointer" />
                      </button>
                      <span className=" w-[30px] h-[30px] text-[white] bg-[--accent-orange] flex items-center justify-center rounded-full">
                        1
                      </span>
                      <button>
                        <MdNavigateNext className="bg-[#f9f9f9] cursor-pointer" />{" "}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
