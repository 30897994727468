import { AiOutlineDelete, AiOutlinePlusCircle } from "react-icons/ai"
import { FiEdit } from "react-icons/fi"
import { NavLink } from "react-router-dom"


function SendingServer({ customerPlans}) {
  return (
   <>
      <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] w-[100%] ">
          <div className="mb-8">
            <div className="mb-4 w-[100%] mt-6 flex md:flex-row flex-col">
              <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
                Below are sending servers that will be used for this plan. In
                other words, sms sent out by users who subscribe to this plan
                will be delivered by these servers.
              </p>
              <div className="ml-4">
                <button className=" bg-[--accent-orange] text-white px-[21px] justify-center items-center text-sm mr-4 rounded-md flex h-[40px] gap-1  ">
                  <AiOutlinePlusCircle className="mr-1" size={20} />
                  <span>
                    <b>Add Sending Server</b>{" "}
                  </span>
                </button>
              </div>
            </div>
            {customerPlans?.sending_servers?.map((val) => (
              <div className="flex  mx-4 mt-6 w-[96%] border-b-2">
                <div className="w-[80%] flex gap-10 mx-4 mb-4">
                  <div className="w-[60%] flex flex-col gap-4">
                    <label className="text-[0.857rem] w-[100%] text-[#726b6b] mb-[0.2857rem] font-semibold">
                      {val.name}
                      <span className="text-red-600"> *</span>
                    </label>
                    <p className="text-[--accent-gray] text-sm">
                      Sending limit
                      <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                        {val.quota_value}
                      </span>
                      /
                      <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                        {val.quota_base} {val.quota_unit}
                      </span>
                    </p>
                  </div>

                  <div className="w-[40%] flex gap-4 justify-center items-center ">
                    <NavLink
                      to={`/admin/sending-servers/${
                        customerPlans.sending_servers.uid
                      }/${
                        customerPlans?.sending_servers?.name === "Supreme SMS"
                          ? "edit"
                          : "editNSES"
                      }`}
                    >
                      <FiEdit
                        className="text-[#67f07e] cursor-pointer"
                        size={20}
                      />
                    </NavLink>
                    <AiOutlineDelete
                      className="text-[#EA5455] cursor-pointer "
                      size={20}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
   </>
  )
}

export default SendingServer