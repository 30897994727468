import React from 'react'

const Subscription = () => {
  return (
    <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
            <div className=" pb-4 border-b ">
              <p className="text-2xl  text-[#5f6368]  ">Current Plan</p>
            </div>

            {/* -------form part 4----- */}
            <div className="bottom  p-2">
              <form action="" className="flex gap-4">
                <div className="left w-[100%]">
                  <div>
                    <p className="text-center py-2 font-medium text-[#17D3EA]">
                      No Active Subscription
                    </p>
                  </div>
                </div>
              </form>
              <div className="flex   justify-end ">
                <button className="bg-[#F08B38] w-full text-center text-white px-[21px] py-[8px] rounded-md  ">
                  New Subscription
                </button>
              </div>
            </div>
          </div>
  )
}

export default Subscription