import { useState, useEffect } from "react";
import { TfiSave } from "react-icons/tfi";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";
import axios from "axios";
import { apiBaseUrl } from "../utils/urls";
import { useToken } from "antd/es/theme/internal";

function CreateCountries() {
  const token = useToken();
  const [countries, setCountries] = useState<any>(null);
  console.log(countries);

  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        name: "required|unique:countries,name",
        iso_code: "required|min:2|max:2|unique:countries,iso_code",
        country_code: "required|min:1|max:3",
        status: "required|boolean",
        
        length: 232,
        start: 0,
        orderBy: "name", 
        orderDir: "asc",
        search: ""

      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://smsvendor.com/api/v1/admin/countries/search",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            console.log(data.data.data);
            setCountries(data?.data.data);
          } else {
            showFailedToastAlert(
              `Getting Countries Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Countries Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

   //state of countrties form
   const [form, setForm] = useState({
    _token:"qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
 name:"",
 iso_code:"",
 country_code:"",
 status:"1"
  });

  //as we need to set initial value of state  with api data we use useeffect()
  useEffect(() => {
    if (countries && countries.length > 0) {
    setForm((prev) => ({
      ...prev,
      name: countries[0]?.name,
      iso_code:countries[0]?.iso_code,
      country_code:countries[0]?.country_code,
    }));
  }
  }, [countries])

  //onchange function for selectbox of countries
  const onChangeSelectBox= (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

    //handle on submit form of countries
    const handleFormSubmit = async (e,data) => {
      e.preventDefault();
      try {
        const response = await axios.post(
          `${apiBaseUrl}/admin/countries`,
          { ...data},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type":'application/x-www-form-urlencoded'
            },
          }
        );
  
        showSuccessfulToastAlert(response?.data.message);
      } catch (error) {
        showFailedToastAlert(error);
      }
    };

  return (
    <div className="main  my-[15px] font-sans  w-[100%]">
      <form onSubmit={(e)=>{handleFormSubmit(e,form)}}>
      <div className="left flex flex-col w-[100%] md:w-[50%]   gap-4">
        <div className="bg-[white] shadow-md rounded-md w-[100%]">
          <h3 className="text-md font-[500] text-[--accent-gray] mx-4 my-4">
            Add New
          </h3>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Name<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              onChange={(e)=>onChangeSelectBox(e,"name")}
              name=""
              id=""
              value={form.name}
            >
              {countries?.map((val,key)=>(
                <option value={val?.name}>{val?.name}</option>
              ))}
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              ISO Code<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              onChange={(e)=>onChangeSelectBox(e,"iso_code")}
              name=""
              id=""
              value={form.iso_code}
            >
              {countries?.map((val,key)=>(
                <option value={val?.iso_code}>{val?.iso_code}</option>
              ))}
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Country Code<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              onChange={(e)=>onChangeSelectBox(e,"country_code")}
              name=""
              id=""
              value={form.country_code}
            >
              {countries?.map((val,key)=>(
                <option value={val?.country_code}>+{val?.country_code}</option>
              ))}
            </select>
          </div>

          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Status<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              onChange={(e)=>onChangeSelectBox(e,"status")}
              name=""
              id=""
              value={form.status}
            >
    
                <option value='1'>Active</option>
                <option value='0'>Disable</option>

            </select>
          </div>
          <div className="mt-4 mb-6 ml-4">
        <button type='submit' className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center ">
          <TfiSave className="mr-1" />
          Save
        </button>
      </div>
        </div>
      
 
      </div>
</form>
    </div>
  );
}

export default CreateCountries;
