import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customersSearched: {},
};

const dashBoardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    setCustomersSearched: (state, action) => {
      state = action.payload;
    },
  },
});

export const { setCustomersSearched } = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
