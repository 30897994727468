import { useState, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import { getToken } from "../utils/tokentUtils";
import { useParams } from "react-router-dom";
import useGetQuery from "../hooks/useGetQuery";
import Currencies from "./Currencies";
import usePostQuery from "../hooks/usePostQuery";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import axios from "axios";

const EditSenderId = () => {
  const token = getToken();
  const { senderid } = useParams();
  const [priceError, setPriceError] = useState<any>("");

  //function for get the data for single senderid
  const { getQueryData: senderIdData } = useGetQuery(
    `/admin/senderid/${senderid}/show`,
    `/admin/senderid/${senderid}/show`
  );

  // state of senderid
  const [senderForm, setSenderForm] = useState<any>({
    sender_id: "",
    status: "",
    price: "",
    billing_cycle: "",
    currency: "",
    user_id: "",
  });

  useEffect(() => {
    setSenderForm((prevsenderForm) => ({
      ...prevsenderForm,
      sender_id: senderIdData?.senderid?.sender_id,
      price: senderIdData?.senderid?.price,
      status: senderIdData?.senderid?.status,
      billing_cycle: senderIdData?.senderid?.billing_cycle,
      currency: senderIdData?.currencies[0].id,
      user_id: senderIdData?.customers[0]?.id,
    }));
  }, [senderIdData]);

  console.log(senderForm);
  console.log(senderForm.currency);

  //function for get the data for single senderid
  // const { queryData: senderID } = usePostQuery(
  //     `/admin/senderid/${senderid}/show`,
  //     `/admin/senderid/${senderid}/show`
  //   );

  //onchange function for selectbox
  const onChangeSelectBox = (e, name) => {
    setSenderForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  //onchange function for input
  // const onChangeFun = (e, name) => {
  //   setSenderForm((prev) => {
  //     return {
  //       ...prev,
  //       [name]: e.target.value,
  //     };
  //   });
  // };
  const onChangeFun = (e, name) => {
    const inputValue = e.target.value.trim();

    if (name === "price") {
    }
    if (inputValue.length > 12) {
      setPriceError(
        "Invalid price length. Price should have a maximum length of 12."
      );
    } else {
      setPriceError("");
    }

    setSenderForm((prev) => {
      return {
        ...prev,
        [name]: inputValue,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(senderForm);
  };

  // const handleFormSubmit =async(e) =>{
  //   e.preventDefault()
  //   try {
  //     const response = await axios.post(
  //       `https://smsvendor.com/api/v1/admin/senderid/${senderid}`,
  //       {...senderForm},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //       console.log(response)
  //     showSuccessfulToastAlert(response?.data.message);
  //   } catch (error) {
  //     showFailedToastAlert(error);
  //   }

  // }

  console.log(senderIdData?.senderid?.price);

  return (
    <>
      <div className="w-[50%] mt-4 p-4 shadow-xl  bg-white rounded-md">
        <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
          Update Sender ID
        </p>
        <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Sender ID
          </span>{" "}
          is what appears on people's phones to show who the SMS is from. It can
          be your{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Brand Name
          </span>{" "}
          or{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Comapany Name
          </span>
          . Set{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Price
          </span>{" "}
          value{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            0
          </span>{" "}
          for free of cost sender id on SMSVENDOR
        </p>
        <form>
          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Sender ID
              <span className="text-red-600"> *</span>
            </label>
            <input
              value={senderForm?.sender_id}
              onChange={(e) => onChangeFun(e, "sender_id")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>
          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
              htmlFor=""
            >
              Status
              <span className="text-red-600"> *</span>
            </label>

            <select
              value={senderForm?.status}
              onChange={(e) => onChangeSelectBox(e, "status")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="Active">
                Active
              </option>
              <option className="text-gray-700" value="payment Required">
                Payment Required
              </option>
              <option className="text-gray-700" value="Block">
                Block
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Price
              <span className="text-red-600"> *</span>
            </label>
            <input
              value={senderForm?.price}
              onChange={(e) => onChangeFun(e, "price")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="number"
              required
            />
            <span className="form-label-size text-red-600" >{priceError}</span>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              
            >
              Billing cycle
              <span className="text-red-600"> *</span>
            </label>
            <select
              value={senderForm?.billing_cycle}
              onChange={(e) => onChangeSelectBox(e, "billing_cycle")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="Daily">
                Daily
              </option>
              <option className="text-gray-700" value="Monthly">
                Monthly
              </option>
              <option className="text-gray-700" value="Yearly">
                Yearly
              </option>
              <option className="text-gray-700" value="Custom">
                Custom
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Select Customer
              <span className="text-red-600"> *</span>
            </label>
            <select
              onChange={(e) => onChangeSelectBox(e, "user_id")}
              value={senderForm.user_id}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              {/* <option className="text-gray-700" value="option1">
                {senderForm?.select_customer}
              </option> */}
              {senderIdData?.customers?.map((val) => (
                <option className="text-gray-700" value={val?.id}>
                  {val?.first_name}({val?.last_name})
                </option>
              ))}
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Currency
              <span className="text-red-600"> *</span>
            </label>
            <select
              onChange={(e) => onChangeSelectBox(e, "currency")}
              value={senderForm.currency}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              {/* <option className="text-gray-700" value="option1">
                Euro(EUR)
              </option> */}
              {/* <option className="text-gray-700" value="option2">
                NPR(NPR)
              </option> */}
              {senderIdData?.currencies?.map((val) => (
                <option className="text-gray-700" value={val.id}>
                  {val.name}({val.code})
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end">
            <button onClick={handleSubmit} className=" accent-btn ">
              <BiSave />
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditSenderId;
