import create from "../assets/create.png";
import { Select, Switch } from "antd";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";
import { useToken } from "antd/es/theme/internal";
import { apiBaseUrl } from "../utils/urls";
import axios from "axios";
import { useState } from "react";


function CreateAdministrator() {

   //state of create administrator form
   const [form, setForm] = useState({
    _token:"qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
 email:"",
 password:"",
 password_confirmation:"",
 first_name:"",
 last_name:"",
 phone:"",
 roles:[1],
 status:1,
 is_admin:1,
 image:'(binary)'
  });

    //onchange function for administrator state
    const onChangeFun = (e, name) => {
      setForm((prev) => {
        return {
          ...prev,
          [name]: e.target.value,
        };
      });
    };

  //onchange function for selectbox of create adminstrator
  const onChangeSelectBox= (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };


  const token = useToken();
      //handle on submit form of create administrator
      const handleFormSubmit = async (e,data) => {
        e.preventDefault();
        try {
          const response = await axios.post(
            `${apiBaseUrl}/admin/administrators`,
            { ...data},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type":'application/x-www-form-urlencoded'
              },
            }
          );
    
          showSuccessfulToastAlert(response?.data.message);
        } catch (error) {
     showFailedToastAlert(`Error:'${error} :has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource`)
      };
    }
  return (
    <div className="bg-white pb-[110px] rounded-[3px] w-[100%] md:w-[50%]">
      <div className="mt-[17px] pb-[23px] border-b-[1px] border-[#D9D9D9] w-[100%] pr-4">
        <h2 className="text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[26px] pl-[18.74px]">
          Create Administrator
        </h2>
      </div>
      <form action="" className="pt-[24px] pl-4 w-[100%] pr-4" onSubmit={(e)=>handleFormSubmit(e,form)}>
        <div className="flex flex-col gap-2">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Email *
          </h3>
          <input
            className="border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]"
            required
            placeholder="Email"
            name="email"
            type="email"
            value={form?.email}
            onChange={(e)=>onChangeFun(e,'email')}
          />
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Password *
          </h3>
          <input
            className="border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]"
            required
            placeholder=""
            name="password"
            type="password"
            value={form?.password}
                        onChange={(e)=>onChangeFun(e,'password')}

          />
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            ConfirmPassword *
          </h3>
          <input
            className="border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]"
            required
            placeholder=""
            name="confirmPassword"
            type="password"
            value={form?.password_confirmation}
            onChange={(e)=>onChangeFun(e,'password_confirmation')}

          />
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Firstname *
          </h3>
          <input
            className="border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]"
            required
            placeholder="First Name"
            name="firstname"
            value={form?.first_name}
            onChange={(e)=>onChangeFun(e,'first_name')}

          />
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Lastname
          </h3>
          <input
            className="border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]"
            placeholder="Last Name"
            name="lastname"
            value={form?.last_name}
            onChange={(e)=>onChangeFun(e,'last_name')}

          />
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Phone *
          </h3>
          <input
            className="border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]"
            required
            placeholder="Phone"
            name="phone"
            type='number'
            value={form?.phone}
            onChange={(e)=>onChangeFun(e,'phone')}

          />
        </div>

        <div className="mt-[20px]">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Roles *
          </h3>
          <select   className="h-[40px] text-[--accent-gray] px-[1rem] outline-[--accent-orange]   border border-grey-500  w-full "  onChange={(e)=>onChangeSelectBox(e,'roles')}>
            <option value='1'>administrator</option>
          </select>
        </div>
        <div className="mt-[20px]">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Status *
          </h3>
          <select
              
               className="h-[40px] text-[--accent-gray] px-[1rem] outline-[--accent-orange]   border border-grey-500  w-full "
              value={form.status}
              onChange={(e)=>onChangeSelectBox(e,'status')}
            >
  
                <option value='1'>Active</option>
                <option value='0'>InActive</option>
            </select>
        </div>

        <div className="mt-[20px] flex flex-col gap-2">
          <h3 className="text-[#5F6368] text-[14px] leading-[14.52px] font-normal">
            Image
          </h3>
          <input
            type="file"
            accept="image/*"
            
            onChange={(e)=>onChangeFun(e,'image')}
            className="border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] flex items-center justify-center text"
          />
          <p className="text-[#229]">
            Recommended size: Width 300px X Height 300px
          </p>
        </div>

        <div className="mt-[20px] flex flex-col">
          <p>Create customer account?</p>
          <p className="text-[#229]">
            Also create a customer account associated with the admin user
          </p>
        </div>
        <div className="mt-[20px] flex flex-col w-[25px]">
          <Switch />
        </div>
        <button type='submit' className="bg-[#0092D7] text-white w-[102px] h-[40px] flex  items-center justify-center gap-[8px] mt-[30px] float-left rounded-[3px] cursor-pointer">
          <img src={create} alt="" />
          <p className="text-[16px]  font-normal">Save</p>
        </button>
      </form>
    </div>
  );
}

export default CreateAdministrator;
