import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../utils/toastAlerts";
import axios from "axios";

const AddNewNumber = () => {
  const token = getToken();

  //state for  adding new numbers
  const [form, setForm] = useState({
    number: "",
    user_id: "",
    price: "",
    status: "",
    capabilities: "",
    currency_id: "",
    billing_cycle: "",
  });



//onchange function for input 
  const onChangeFun = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  //onchange function for selectbox
  const onChangeSelectBox = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

//   const handleSubmit = (e) =>{
//     e.preventDefault()
//     setForm(...form,)
//     console.log(form)
//   }





  return (
    <>
      <div className="w-[50%] mt-4 p-4 shadow-xl  bg-white rounded-md">
        <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
          Add New Number
        </p>
        <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Numbers
          </span>{" "}
          allow you to be{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            recognisable and reachable
          </span>{" "}
          for your customers: provide a response option to your{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            marketing messages
          </span>
          , be reachable locally in another country and manage your{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            inbound messages
          </span>{" "}
          if your sms gateway support this feature.
        </p>
        <form>
          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Number
              <span className="text-red-600"> *</span>
            </label>
            <input
                value={form?.number}
              onChange={(e) => onChangeFun(e, "number")}
              placeholder="Required"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="number"
              required
            />
          </div>
          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
              htmlFor=""
            >
              Status
              <span className="text-red-600"> *</span>
            </label>

            <select
                value={form?.status}
                onChange={(e) => onChangeSelectBox(e, "status")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="Available">
                Available
              </option>
              <option className="text-gray-700" value="Assigned">
                Assigned
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Capabilities
              <span className="text-red-600"> *</span>
            </label>
            <select
              value={form?.status}
              onChange={(e) => onChangeSelectBox(e, "capabilities")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="SMS">
                SMS
              </option>
              <option className="text-gray-700" value="Voice">
                Voice
              </option>
              <option className="text-gray-700" value="MMS">
                MMS
              </option>
              <option className="text-gray-700" value="WhatsAPP">
                WhatsAPP
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Price
              <span className="text-red-600"> *</span>
            </label>
            <input
                value={form.price}
                onChange={(e) => onChangeFun(e, "price")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="number"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Billing cycle
              <span className="text-red-600"> *</span>
            </label>
            <select
                value={form?.billing_cycle}
                onChange={(e) => onChangeSelectBox(e, "billing_cycle")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="Daily">
                Daily
              </option>
              <option className="text-gray-700" value="Monthly">
                Monthly
              </option>
              <option className="text-gray-700" value="Yearly">
                Yearly
              </option>
              <option className="text-gray-700" value="Custom">
                Custom
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Select Customer
              <span className="text-red-600"> *</span>
            </label>
            <select
                onChange={(e) => onChangeSelectBox(e, "user_id")}
                value={form.user_id}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              
            >
              {/* <option className="text-gray-700" value="option1">
                {senderForm?.select_customer}
              </option> */}
              <option value="1">Super Admin</option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Currency
              <span className="text-red-600"> *</span>
            </label>
            <select

                onChange={(e) => onChangeSelectBox(e, "currency_id")}
                value={form.currency_id}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              
            >
              <option className="text-gray-700" value="2">
                Euro(EUR)
              </option>
              <option className="text-gray-700" value="12">
                NPR(NPR)
              </option>
            </select>
          </div>

          <div className="flex  gap-2 justify-end">
            <button   className=" accent-btn ">
              <BiSave />
              Save
            </button>
            <button className=" flex gap-[0.5rem] items-center cursor-pointer bg-white text-[var(--accent-orange)] border border-[var(--accent-orange)]  mb-[1rem] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] ">
              <FiRefreshCw />
              Reset
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewNumber;
