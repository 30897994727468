import { BrowserRouter, Routes, Route,} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Addnewplan from "../components/Addnewplan";
import Customer from "../components/Customer";
import Newsubscription from "../components/Newsubscription";
import Plans from "../components/Plans";
import SendingServers from "../components/SendingServers";
import Subscriptions from "../components/Subscriptions";
import Dashboard from "../pages/Dashboard";
import Dashboardview from "../pages/Dashboardview";
import Login from "../pages/Login";
import "react-toastify/dist/ReactToastify.css";
import Newcustomer from "../components/Newcustomer";
import ChooseSendingServer from "../components/ChooseSendingServer";
import EditCustomer from "../components/EditCustomer";
import SubscriptionLogs from "../components/SubscriptionLogs";
import Currencies from "../components/Currencies";
import AddNewCurrencies from "../components/AddNewCurrencies";
import UpdateCurrencies from "../components/UpdateCurrencies";
import SenderId from "../components/SenderId";
import PhoneNumbers from "../components/PhoneNumbers";
import Tags from "../components/Tags";
import Keywords from "../components/Keywords";
import { ThemeCustomizer } from "../components/ThemeCustomizer";
import Blacklists from "../components/Blacklists";
import SpamWord from "../components/SpamWord";
import Administrators from "../components/Administrators";
import Roles from "../components/Roles";
import Settings from "../components/Settings";
import Countries from "../components/Countries";
import Languages from "../components/Languages";
import PaymentGateways from "../components/PaymentGateways";
import EmailTemplates from "../components/EmailTemplates";
import UpdateApplication from "../components/UpdateApplication";
import Invoices from "../components/Invoices";
import Reports from "../components/Reports";
import EditSendingServer from "../components/EditSendingServer";
import EditPlan from "../components/EditPlan";
import EditSendingServerNSES from "../components/EditSendingServerNSES";
import EditPaymentGateways from "../components/EditPaymentGateways";
import UpdateEmailTemplate from "../components/UpdateEmailTemplate";
import CreateCountries from "../components/CreateCountries";
import CreateLanguage from "../components/CreateLanguage";
import EditRole from "../components/EditRole";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// we can uninstall this for production.
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import AddCoverage from "../components/AddCoverage";
import Account from "../components/Account";
import CreateAdministrator from "../components/CreateAdministrator";
import CreateRole from "../components/CreateRole";
import EditSenderId from "../components/EditSenderId";
import AddNewSenderId from "../components/AddNewSenderId";
import AddNewNumber from "../components/AddNewNumber";
import CreateNewKeyword from "../components/CreateNewKeyword";
import NewTemplateTag from "../components/NewTemplateTag";



const Router = () => {
  const client = new QueryClient();

  const token = localStorage.getItem("token");




  return (
    <QueryClientProvider client={client} >
    <div>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={(!token)?<Login />:<Dashboard />} />
          

          <Route path="/admin" element={<Dashboard />}>
            <Route index element={<Dashboardview />} />
            {/* accounts section */}
            <Route path="/admin/account" element={<Account/>}  />

            

            {/* customer section started */}
            <Route path="/admin/customers" element={<Customer />} />
            <Route path="/admin/customers/:customer/show" element={<EditCustomer />} />
            <Route
              path="/admin/customers/new_customer"
              element={<Newcustomer />}
            />
            <Route path="/admin/subscriptions" element={<Subscriptions />} />
            <Route path="/admin/subscriptions/create" element={<Newsubscription />} />
            <Route path="/admin/subscriptions/:subscription/logs" element={<SubscriptionLogs />} />

            {/* plans section started */}
            <Route path="/admin/plans" element={<Plans />} />
            <Route path="/admin/plans/new_plan" element={<Addnewplan />} />
            <Route path="/admin/plans/:plan/show" element={<EditPlan />} />
            <Route path="/admin/currencies"  element={<Currencies />}  />
            <Route path="/admin/currencies/create" element={<AddNewCurrencies />} />
            <Route path="/admin/currencies/:currencies/show" element={<UpdateCurrencies />} />
            <Route path="/admin/plans/:plan/coverage" element={<AddCoverage />} />

            {/* sending section started */}
            <Route path="/admin/sending" element={<SendingServers />} />
            <Route path="/admin/sending-servers/select"  element={<ChooseSendingServer />} />
            <Route path="/admin/sending-servers/:server/edit"  element={<EditSendingServer />} />
            <Route path="/admin/sending-servers/:server/editNSES"  element={<EditSendingServerNSES/>} />
            <Route path="/admin/senderid"  element={<SenderId />} />
            <Route path="/admin/senderid/create"  element={<AddNewSenderId />} />
            <Route path="/admin/senderid/:senderid/show"  element={<EditSenderId />} />
            <Route path="/admin/phone-numbers"  element={<PhoneNumbers />} />
            <Route path="/admin/phone-numbers/create"  element={<AddNewNumber />} />
            <Route path="/admin/keywords"  element={<Keywords />} />
            <Route path="/admin/keywords/create"  element={<CreateNewKeyword />} />
            <Route path="/admin/tags"  element={<Tags />} />
            <Route path="/admin/tags/create"  element={<NewTemplateTag />} />

            {/* security section started */}
            <Route path="/admin/blacklists" element={<Blacklists />} />
            <Route path="/admin/spam-word" element={<SpamWord />} />

            {/* Adminstrative section started */}
            <Route path="/admin/administrators" element={<Administrators />} />
            <Route path="/admin/administrators/create" element={<CreateAdministrator />} />
            <Route path="/admin/roles"  element={<Roles />} />
            <Route path="/admin/roles/create"  element={<CreateRole />} />
            <Route path="/admin/roles/:role/show"  element={<EditRole />} />

            {/* Setting section started */}
            <Route path="/admin/settings" element={<Settings />} />
            <Route path="/admin/countries" element={<Countries />} />
            <Route path="/admin/countries/create" element={<CreateCountries />} />
            <Route path="/admin/languages" element={<Languages />} />
            <Route path="/admin/languages/create" element={<CreateLanguage/>} />

            <Route path="/admin/payment-gateways" element={<PaymentGateways />} />
            <Route path="/admin/payment-gateways/:gateway/show" element={<EditPaymentGateways />} />
            <Route path="/admin/email-templates" element={<EmailTemplates />} />
            <Route path="/admin/email-templates/:template/show" element={<UpdateEmailTemplate />} />

            <Route path="/admin/update-application" element={<UpdateApplication />} />
            {/* Reports section started */}

            <Route path="/admin/invoices" element={<Invoices />} />
            <Route path="/admin/reports"  element={<Reports />} />


            <Route path="/admin/customizer" element={<ThemeCustomizer/>} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
    <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default Router;
