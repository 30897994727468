import { AiOutlineCheckSquare } from "react-icons/ai";
import { useState, useEffect } from "react";
import { useToken } from "antd/es/theme/internal";
import { showSuccessfulToastAlert } from "../utils/toastAlerts";

function UpdateApplication() {
 // const token = useToken();
  const [checkUpdates, setCheckUpdates] = useState<any>('updateApplication');

  //logic to toggle page
  const handleClick = (name: string) => {
      setCheckUpdates(name);
  };

      //state for updateApplication
      const [form, setForm] = useState({
  purchase_code:"",
  update_file:""
      });
    
      //as we need to set initial value of state  with api data we use useeffect()
    /*useEffect(() => {
      setForm((prev) => ({
        ...prev,

    }, []);*/
    
      //onchange function for form state
      const onChangeFun = (e, name) => {
        setForm((prev) => {
          return {
            ...prev,
            [name]: e.target.value,
          };
        });
      };
  

  /*
          // handle on submit form of updateApplication
          const handleFormSubmit = async (e,data) => {
            e.preventDefault();
            try {
              const response = await axios.post(
                `${apiBaseUrl}/admin/email-templates/${template}`,
                {...data},
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type":'application/x-www-form-urlencoded'
                  },
                }
              );
        
              showSuccessfulToastAlert(response?.data.message);
            } catch (error) {
              showFailedToastAlert(error);
            }
          };
          */
  return (
    <div className="w-[100%]  flex items-center] mt-5 font-sans text-[20px]">
      {checkUpdates === "updateApplication" && (
        <div className="flaot-left rounded-md flex flex-col gap-3 p-4 md:ml-3 bg-[white] shadow-[0px]-[10px]-[15px]-[-3px]-[rgba(0,0,0,0.1)] md-w-[60%] md:h-[38%] h-[45%] md:w-[60%] w-[100%]">
          <div className="border-b border-grey-200 w-[95%] mt-4 flex flex-col gap-1.5">
            <h3 className="text-[--accent-gray]">
              You are currently running on Ultimate SMS{" "}
              <span className="text-[#c75594] bg-green-100 rounded-sm px-1">
                2.8
              </span>
            </h3>
            <h3 className="text-[--accent-gray]">
              {" "}
              <span className="text-[#8c00ff]">CONGRATULATION!!!</span> You are
              using latest version
            </h3>
          </div>

        <button onClick={()=>handleClick("checkUpdates")} className='w-[180px] font-sans bg-orange-500 opacity-80 mt-2 h-[40px] flex items-center justify-center rounded-md text-[16px] text-white'>
          <AiOutlineCheckSquare className="mr-1"/>  Check for Updates</button>
          </div>
      )}
      {checkUpdates === "checkUpdates" && (
        <div className=" bg-white p-6 sm:p-[2rem]  md:p-[2rem] rounded-md shadow-md w-[100%] md:w-[60%] ">
          <h3 className="text-[#EE7576] mb-4  ">
            Before update, please take a backup you're all files and database.
          </h3>
          <p className="text-[var(--accent-gray)] form-label-size mb-4 ">
            Your are currently running on Ultimate SMS <span className="text-[#EE7576] form-label-size bg-[#EEEEEE] ">2.8 </span> To upgrade your
            application, please download the latest build from codecanyon.net ,
            then you can find the upgrade package located at downloaded folder
            called <span className="text-[#EE7576] form-label-size bg-[#EEEEEE] ">3.*.*_update.zip</span> , upload it using the form below. Please make
            sure the upgrade package file size does not exceed the following
            upload limit settings:
          </p>
          <p className="text-[var(--accent-gray)] mb-4 form-label-size ">
            Please make sure the upgrade package file size does not exceed the
            following upload limit settings:
          </p>
          <div className="flex flex-col mb-4 gap-2">
            <div className="text-[#EE7576] form-label-size flex items-center gap-1">
            <AiOutlineCheckSquare /> <span>Post_max_size</span> <label className="text-[#7367F0] font-[400] ">256</label>
            </div>
            <div className="text-[#EE7576] form-label-size flex items-center gap-1">
            <AiOutlineCheckSquare /><span> Upload_max_filesize</span> <label className="text-[#7367F0] ">256</label>
            </div>
          </div>

          <div className="input-feild">
            <div className=" flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Purchase code
                <span className="text-red-600"> *</span>
              </label>
              <input
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
                value={form.purchase_code}
                onChange={(e)=>onChangeFun(e,'purchase_code')}
                required
              />
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Update File
              </label>
              <div>
                <input
                  className="bg-white w-full outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                  type="file"
                  name="archive" accept=".zip,.rar,.7z,.gz"
                  value={form.update_file}
                  onChange={(e)=>onChangeFun(e,'update_file')}
                />
                <label className="text-[#7367F0] form-label-size ">
                  {" "}
                  please upload only file{" "}
                  <span className="text-[#EE7576] form-label-size bg-[#EEEEEE] ">
                    3.6.0_update.zip
                  </span>
                  file
                </label>
              </div>
            </div>
          </div>
          <div>
            <button className="accent-btn font-normal" onClick={()=>showSuccessfulToastAlert(`message:Successful:<br>${form.purchase_code}, ${form.update_file}`)}>
              <AiOutlineCheckSquare /> Upload
            </button>
          </div>
        </div>
      )}

     

  </div>)
 
}

export default UpdateApplication;
