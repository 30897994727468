import React, { useEffect, useState } from "react";
import customers from "../assets/cusotmers.png";
import plans from "../assets/plans.png";
import sms from "../assets/smssent.png";
import compaigns from "../assets/compainssent.png";
import customerGrowth from "../assets/customergrowth.png";
import revenue from "../assets/revenue.png";
import {
  PieChart,
  Tooltip,
  Pie,
  Cell,
  BarChart,
  Bar,
  Legend,
  LineChart,
  Line,
} from "recharts";

const Dashboardview = () => {
  const [barGraphWidth, setBarWidth] = useState<any>(
    document.getElementById("barchart")?.clientWidth
  );
  const [lineGraphWidth, setLineWidth] = useState<any>(
    document.getElementById("outsms")?.clientWidth
  );

  
  useEffect(() => {
   return ()=>{window.addEventListener("resize", handleResize);}
  }, []);

  useEffect(()=>{
    setTimeout(() => { 
      setBarWidth(document.getElementById("barchart")?.clientWidth)
      setLineWidth(document.getElementById("outsms")?.clientWidth)
    }, 1000);
  },[])

  function handleResize() {
    const lineChartContainer = document.getElementById("outsms");
    const barChartContainer = document.getElementById("barchart");
    setBarWidth(barChartContainer?.clientWidth);
    setLineWidth(lineChartContainer?.clientWidth);
    // Code to handle screen size changes
  }

  // using pie chart and their data
  const piedata = [
    { name: "Delivered", value: 5590 },
    { name: "Failed", value: 50 },
  ];
  const COLORS = ["#67c2ed", "#e12d2d"];

  // usig bar graph for customer growth and their data.
  const bargraphdata = [
    { name: "Jan", CustomerGrowth: 2400 },
    { name: "Feb", CustomerGrowth: 1398 },
    { name: "Mar", CustomerGrowth: 9800 },
    { name: "Apr", CustomerGrowth: 3908 },
  ];

  // using line graph for showing outgoing sms and their data.
  const outGoingSms = [
    { name: "Jan", sms: 2400 },
    { name: "Feb", sms: 1398 },
    { name: "Mar", sms: 9800 },
    { name: "Apr", sms: 3908 },
    { name: "May", sms: 4800 },
    { name: "Jun", sms: 3800 },
    { name: "Jul", sms: 4300 },
  ];

  // using line graph for showing incomming sms and their data.
  const inCommingSms = [
    { name: "Jan", sms: 2400 },
    { name: "Feb", sms: 198 },
    { name: "Mar", sms: 980 },
    { name: "Apr", sms: 3908 },
    { name: "May", sms: 4800 },
    { name: "Jun", sms: 800 },
    { name: "Jul", sms: 2300 },
  ];

  return (
    <div className="w-full mt-[20px] xl:pr-[40px] 2xl:pr-0 px-[12px] md:px-0  ">
      <div className="bg-white pt-[18px] pl-[18px] pb-[23.5px] rounded-[2px] border-4 w-full pr-[10px] md:pr-0">
        <p className="text-[16px] leading-[25.6px] text-[#5F6368] font-normal pb-[15px]">
          Statistics
        </p>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-[30px] ">
          <div className="flex items-center gap-[14px] cursor-pointer relative">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#0092D7]  font-normal">
                3
              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                Customers
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#E6F7FF] rounded-full flex items-center justify-center">
              <img src={customers} alt="" />
            </div>
            <div className=" absolute top-0 right-[30%] h-[100%] bg-[#EDEDED] w-[1px] hidden md:block"></div>
          </div>
          <div className="flex items-center gap-[14px] cursor-pointer relative">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#24E073]  font-normal">
                3
              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                Plans
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#E5F8ED] rounded-full flex items-center justify-center">
              <img src={plans} alt="" />
            </div>
            <div className="absolute top-0 right-[30%] h-[100%] bg-[#EDEDED] w-[1px] hidden md:block"></div>
          </div>
          <div className="flex items-center gap-[14px] cursor-pointer relative">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#E12D2D]  font-normal">
                5600
              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                SMS Sent
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#FCEAEA] rounded-full flex items-center justify-center">
              <img src={sms} alt="" />
            </div>
            <div className=" absolute top-0 right-[30%] h-[100%] bg-[#EDEDED] w-[1px] hidden md:block"></div>
          </div>
          <div className="flex items-center gap-[14px] cursor-pointer">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#F79236]  font-normal">
                22
              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                Campaigns Sent
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#FFF2E6] rounded-full flex items-center justify-center">
              <img src={compaigns} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <Statistics customers plans sms compaigns /> */}
      {/* <---------------------------------------------------------------------------------- */}

      <div className="md:flex   mt-[32px] gap-[34.52px] w-full ">
        <div className="flex  basis-[33%] bg-white  pt-[14px] pl-[17.74px] h-[355px] rounded-[2px] border-4 relative">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal">
            Customer Growth
          </h2>
          <div
            className="w-[98%] h-[221px] absolute bottom-[15%]"
            id="barchart"
          >
            {/* using bar graph for showing customer growth */}
            <BarChart
              width={barGraphWidth}
              height={300}
              data={bargraphdata}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              
              <Tooltip />
              <Legend />
              <Bar dataKey="CustomerGrowth" fill="#67c2ed" />
            </BarChart>
          </div>
        </div>

        <div className="flex flex-col items-center bg-white h-[355.5px] basis-[33%] rounded-[5px] relative  border-4 ">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[18px] pl-[18px]">
            SMS Report
          </h2>

          <div className="w-full flex items-center justify-center">
          <PieChart width={330} height={330}>
            {/* using pie chart for showing sms report */}
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={piedata}
              cx={180}
              cy={140}
              outerRadius={100}
              innerRadius={2}
              label
            >
              {piedata.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
           
          </PieChart>
          </div>

          <div className="flex items-center justify-between border-t-[2px] border-[#EDEDED] pt-[4px]  absolute bottom-[0%] w-full">
            <div className="text-center pl-[54.5px]">
              <p className="text-[#AEAEAE] text-[13px]  leading-[15.23px] font-bold">
                DELIVERED
              </p>
              <h2 className="text-[#5F6368] text-[29.33px] leading-[34.38px] font-normal mt-[10px]">
                5590
              </h2>
            </div>
            <div className="pr-[72px] text-center">
              <p className="text-[#AEAEAE] text-[13px]  leading-[15.23px] font-bold">
                FAILED
              </p>
              <h2 className="text-[#5F6368] text-[29.33px] leading-[34.38px] font-normal mt-[10px]">
                50
              </h2>
            </div>
          </div>
        </div>
     

      {/* <---------------------------------------------------------------------------------- */}

      <div className="flex flex-col items-center bg-white h-[355.5px] basis-[33%] rounded-[5px] relative  border-4">
        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[14px] pl-[17.74px]">
          Revenue this month
        </h2>
        <img
          src={revenue}
          alt=""
          className="w-[80%] h-[190px]  absolute left-[10%] object-cover  bottom-[10%]"
        />
      </div>

      </div>
      {/* <---------------------------------------------------------------------------------- */}
      <div className="flex gap-2 flex-col 2xl:flex-row w-full ">
      <div className=" flex-1  bg-white mt-[37px] h-[186.36px] rounded-[2px] border-4 ">
        {/* line graph for outgoing sms  */}
        <div id="outsms">
          <LineChart
            width={lineGraphWidth}
            height={100}
            data={outGoingSms}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <Tooltip />
            <Line
              type="monotone"
              dataKey="sms"
              stroke="#67c2ed"
              strokeWidth={4}
            />
          </LineChart>
        </div>
        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[18px] pl-[27.57px]">
          Outgoing SMS History of this Month
        </h2>
      </div>
      {/* <---------------------------------------------------------------------------------- */}
      <div className="flex-1 bg-white mt-[37px] h-[186.36px] rounded-[2px] mb-[52.28px] border-4">
        {/* line graph for incomming sms  */}

        <LineChart
          width={lineGraphWidth}
          height={100}
          data={inCommingSms}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <Tooltip />
          <Line
            type="monotone"
            dataKey="sms"
            stroke="#67c2ed"
            strokeWidth={4}
          />
        </LineChart>

        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[18px] pl-[27.57px]">
          incoming SMS History of this Month
        </h2>
      </div>
      </div>
    </div>
  );
};

export default Dashboardview;
