import { useState, useEffect } from "react";
import { RiAccountCircleFill } from "react-icons/ri";
import {  HiOutlineUpload } from "react-icons/hi";
import { RiDeleteBinLine } from "react-icons/ri";
import { TfiSave } from "react-icons/tfi";
import { VscAccount } from "react-icons/vsc";
import {  BiInfoCircle } from "react-icons/bi";
import {  FiLock } from "react-icons/fi";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import { Switch } from "antd";
import DialogBox from "./DialogBox";
import { showFailedToastAlert } from "../utils/toastAlerts";
import Security from "./AccountProfile/Security";
import Notifications from "./AccountProfile/Notifications";

const Account = () => {
  const token = getToken();

  // // for showing and hiding password and confirm password.
  const [showPassword, setShowPassword] = useState<any>(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState<any>(true);

  // // for showing diffrent forms like accounts, permission, subscription etc
  const [show, setshow] = useState<any>("account");

  // function that handles the navigation click of account page.
  const handleNavClick = (name: string) => {
    setshow(name); // turn on the selected dropdown and turn off others
  };

  let postData = {
    length: 10,
    start: 0,
    orderBy: "message",
    orderDir: "asc",
    search: "",
  };

  const [searchinput, setSearchInput] = useState<any>("");
  const [notifications, setNotifications] = useState<any>(null);


  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "message",
        orderDir: "asc",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://smsvendor.com/api/v1/users/account/notifications",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          const data = response?.data;
          console.log(data);
          if (data?.status === "success") {
            console.log(data);
            setNotifications(data?.data.data);
          } else {
            showFailedToastAlert(
              `Getting Customers Number error ${data?.message}`
            );
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

  console.log(notifications);


  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue: any) => {
    setTable(paginatedValue);
  };

  const data = dataForTable?.map((val: any) => ({
    id: val.uid,
    message: val.message,
    mark_read: val.mark_read,
    notification_type: val.notifications_type,
  }));

  const columns: any = [
    {
      name: "TYPE",
      selector: (row: any) => (
        <>
          <div className="flex gap-[10px] cursor-pointer">
            {" "}
            <div>
              <p className="text-[14px] text-[#5F6368]">
                {row.notification_type}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "MESSAGE",
      selector: (row) => row.message,
    },
    {
      name: "MARK AS READ",
      selector: (row) => <Switch checked={row.mark_read} />,
    },
    {
      name: "ACTIONS",
      selector: (row) => (
        <div className="flex gap-4 ">
          <DialogBox title="delete" />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-2">
        <div className="flex py-4 gap-4">
          <button
            onClick={() => handleNavClick("account")}
            className={`${
              show === "account"
                ? "bg-[#F08B38] shadow-md text-white"
                : "bg-transparent  text-[var(--accent-gray)]"
            } px-5 py-2 rounded-md  flex gap-1 items-center  `}
          >
            <VscAccount />
            Account
          </button>

          <button
            onClick={() => handleNavClick("security")}
            className={`${
              show === "security"
                ? "bg-[#F08B38] shadow-md text-white"
                : "bg-transparent text-[var(--accent-gray)]"
            } px-5 py-2 rounded-md  flex gap-1 items-center `}
          >
            <BiInfoCircle />
            security
          </button>

          <button
            onClick={() => handleNavClick("notifications")}
            className={`${
              show === "notifications"
                ? "bg-[#F08B38] shadow-md text-white"
                : "bg-transparent text-[var(--accent-gray)]"
            } px-5 py-2 rounded-md  flex gap-1 items-center  `}
          >
            <FiLock />
            Notifications
          </button>
        </div>

        {/* form1 --- account sections started */}
        {show === "account" && (
          <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
            <div className="top flex gap-4 items-center">
              <div>
                <RiAccountCircleFill size={150} />
              </div>
              <div>
                <div className="flex gap-4 my-2">
                  <button className="bg-[#F08B38] hover:shadow-lg text-white px-3 py-1 rounded-md flex items-center gap-1 ">
                    <HiOutlineUpload />
                    Upload
                  </button>

                  <button className="bg-[#EA5455] hover:shadow-lg text-white px-3 py-1 rounded-md flex items-center gap-1 ">
                    <RiDeleteBinLine />
                    remove
                  </button>
                </div>
                <div>
                  <p className="form-label-size text-[var(--accent-gray)] ">
                    Recommended size: Width 300px ☓ Height 300px
                  </p>
                </div>
              </div>
            </div>

            {/* -------form part 1----- */}
            <div className="bottom  p-2">
              <form action="" className="flex gap-4">
                <div className="left w-[50%]">
                  <div className="w-full ">
                    <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
                      Email<span className="text-red-600"> *</span>
                    </label>
                    <input
                      // value={form1.email}
                      type="email"
                      className="bg-white outline-1 w-full outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400] "
                      name="email"
                      // onChange={handleForm1Change}
                    />
                  </div>

                  <div className="right-sub flex  gap-2 mt-[7px] mb-[16px] items-center  ">
                    <div className="flex flex-col items-center w-[50%]  gap-1">
                      <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start ">
                        First name<span className="text-red-600"> *</span>
                      </label>
                      <input
                        // value={form1?.firstName}
                        type="text"
                        className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem] "
                        name="firstName"
                        // onChange={handleForm1Change}
                      />
                    </div>
                    <div className="flex flex-col items-center w-[50%] gap-1 text-left">
                      <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start ">
                        Last name
                      </label>
                      <input
                        // value={form1?.lastName}
                        type="text"
                        className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem] "
                        name="lastName"
                        // onChange={handleForm1Change}
                      />
                    </div>
                  </div>
                </div>
                <div className="Right w-[50%]">
                  <div className="flex flex-col mb-[16px] ">
                    <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start ">
                      Timezone<span className="text-red-600"> *</span>
                    </label>
                    <input
                      type="text"
                      // value={form1.timezone}
                      className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem] "
                      name="timezone"
                      // onChange={handleForm1Change}
                    />
                  </div>
                  <div className="flex flex-col mb-[16px] ">
                    <label className="form-label-size text-[var(--accent-gray)]  mb-[0.2857rem] self-start  ">
                      Language<span className="text-red-600"> *</span>
                    </label>
                    <select
                      name="language"
                      // onChange={handleForm1Change}
                      id=""
                      className="rounded-[0.357rem] bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] w-full text-[var(--accent-gray)] block text-[1rem] font-[400]  py-[8px] px-[1rem]"
                    >
                      {/* {customerData?.languages?.map((option, key) => (
                        <option key={key} value={option?.code}>
                          {option?.name}
                        </option>
                      ))} */}
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                    </select>
                  </div>
                </div>
              </form>
              <div className="flex justify-end mt-4">
                <button
                  // onClick={handleForm1Submit}
                  className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
                >
                  <TfiSave />
                  save changes
                </button>
              </div>
            </div>
          </div>
        )}

        {/* account sections ended */}

        {/* security  section started */}
        {show === "security" && (
          <Security/>
        )}

        {/* security section ended */}

        {/* notifications  section started */}
        {show === "notifications" && (
          <Notifications/>
        )}
      </div>
    </>
  );
};

export default Account;
