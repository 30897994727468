import React, {useState, useEffect} from 'react'
import search from "../assets/search.png"
import DataTable from 'react-data-table-component';
import { showFailedToastAlert } from '../utils/toastAlerts'
import axios from 'axios'
import { getToken } from '../utils/tokentUtils'
import ReactPaginate from 'react-paginate';
import FormSkeleton from './FormSkeleton';
import HandlingPagination from './HandlingPagination';


const columns: any = [
    {
        name: 'Name',
        selector: row => row.name,
    },
    {
        name: 'Type',
        selector: row => <div className='bg-[#0092D7] w-[60px] p-[10px] h-[18px] rounded-[5px] flex items-center justify-center cursor-pointer'><p className='text-[12px] font-normal leading-[13px] text-white'>{row.type}</p></div>,
    },
    {
        name: 'Action',
        selector: row => <button className='bg-[#0092D7] p-[10px] text-center text-[#fff] rounded-md'>Choose</button>,
    },
];

const ChooseSendingServer = () => {

    const [searchinput,setSerchInput] = useState<any>();
    const [allServer,setAllServer] = useState<any>(null);

    console.log(allServer);

    useEffect(() => {
        try {
          const token = getToken();

          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: "https://smsvendor.com/api/v1/admin/sending-servers/select",
            headers: {
              Authorization:`Bearer ${token}`,
              "Content-Type": "application/json",
            }
          };
    
          axios
            .request(config)
            .then((response) => {
              //   console.log(response.data);
              const data = response?.data;
              if (data?.status === "success") {
                setAllServer(data?.data.data)
                
              } else {
                showFailedToastAlert(
                  `Getting Customers Number error ${data?.message}`
                );
              }
            })
            .catch((error) => {
              console.log(`Getting Customers Number error ${error?.message}`);
            });
        } catch (error) {}
      },[searchinput]);


     


    // changing nesed object into nestd arrays....
      const newNestedArr = allServer && Object.keys(allServer).map(outerKey => {
        const innerObj = allServer[outerKey];
        return { ...innerObj };
      });
  

    // total data that we get from server.
    const data = newNestedArr?.filter((val)=>{
      if(searchinput===""){
        return val
      }else if(val.name.toLowerCase().includes(searchinput.toLowerCase())){
        return val
      }
    }).map((val)=>({
        name:val.name,
        type:val.type
    }));

    // data that have been returned from pagination.
    const [dataForTable, setTable] = useState<any>();
    const getDataForTable = (paginatedValue)=>{
      setTable(paginatedValue)
    }
  

    return (
        <div className='bg-white'>
            <div className='mt-[16px] pt-[23px] pb-[12px] items-center flex flex-col md:flex-row gap-[20px] md:gap-0 justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]'>
                <div className='flex items-center'>
                    <h2 className='text-[#fff] bg-[#0092D7] text-[16px] leading-[18.75px] font-bold  p-[19.74px] text-center rounded-md'>Create own sending server +</h2>
                </div>
            </div>

            {/* ------------------------------------------------ */}
            <div className='flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]'>
                <div className='flex items-center gap-[8px]'>
                    <p className='text-[#5F6368] text-[13px] leading-[15.23px] font-normal'>Show</p>
                    <select name="num" id="num" className=' border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer'>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <p className='text-[#5F6368] text-[13px] leading-[15.23px] font-normal'>Entries</p>
                </div>
                <div className='border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between'>
                    <input onChange={(e)=>setSerchInput(e.target.value)} type="text" placeholder='Search' className='pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal' />
                    <img src={search} alt="" className='cursor-pointer' />
                </div>
            </div>
            {/* ------------------------------------------------ */}
            
           { allServer===null ? <FormSkeleton titles={['Name','Type',"Action"]} />:
            <DataTable
                columns={columns}
                data={dataForTable}
                selectableRows
                className='px-[10px] h-[550px]'
            />}

          <HandlingPagination data={data} getDataForTable={getDataForTable} />

        </div>
    )
}

export default ChooseSendingServer