import { useState } from "react";
import { BiSave } from "react-icons/bi";
import useGetQuery from "../../hooks/useGetQuery";
import TimeZonePicker from "../../TimeZonePicker";
import usePostQuery from "../../hooks/usePostQuery";
import { getToken } from "../../utils/tokentUtils";
import axios from "axios";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../../utils/toastAlerts";

const GeneralSection = () => {
  const token = getToken();


  // state for general sections forms
  const [generalForm, setGeneralForm] = useState<any>({
    app_name: "",
    app_title: "",
    app_keyword: "",
    company_address: "",
    footer_text: "",
    app_logo: "",
    app_favicon: "",
    country: "",
    timezone: "",
    date_format: "",
    language: "",
    _token: "vfcBtwNED453WPD5m0SBvDAdBOAORG39V65iMUGs",
  });






   // getting all countries
 let postDataForCountries = JSON.stringify({
  length: 100,
  start: 0,
  orderBy: "id",
  orderDir: "asc",
  search: "",
});
const {  queryData: countries} = usePostQuery("/admin/countries/search","/admin/countries/search", postDataForCountries );




  // to get data of settings from API
  const { getQueryData: settingsData } = useGetQuery(
    `/admin/settings`,
    `/admin/settings`
  );

  // thsi is callback function for TimeZone component.
  const getTimeZone = (timezone) => {
    setGeneralForm((prevForm1) => ({
      ...prevForm1,
      timezone: timezone,
    }));
  };

  // handle onchange function for all the inputs fields
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setGeneralForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // handle onchange function for input type=file
  const handleFilechange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    setGeneralForm((prev) => {
      return {
        ...prev,
        [name]: file,
      };
    });
  };

    // throwing error while post method request



    // handling general-form-section submit
    // const handleGeneralFormSubmit =async(e) =>{
    //   e.preventDefault();
    //   try {
    //     const response = await axios.post(
    //       `https://smsvendor.com/api/v1/admin/settings`,
    //       {...generalForm},
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );

    //     console.log(response.data)
    //     showSuccessfulToastAlert(response?.data.message);
    //   } catch (error) {
    //     showFailedToastAlert(error);
    //   }
      
    // }
  
    const handleGeneralFormSubmit = () =>{
      console.log(generalForm)
    }








  console.log(generalForm);

  return (
    <>
      <div className="w-[100%] md:w-[60%] mt-[2rem]  px-4">
        <form action="">
          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor="app_name"
            >
              Application Name
              <span className="text-red-600"> *</span>
            </label>
            <input
              name="app_name"
              id="app_name"
              onChange={onChangeFun}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="app_title"
            >
              Application Title
              <span className="text-red-600"> *</span>
            </label>
            <input
              id="app_title"
              name="app_title"
              onChange={onChangeFun}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="app_keyword"
            >
              Application Keyword
              <span className="text-red-600"> *</span>
            </label>
            <input
              id="app_keyword"
              name="app_keyword"
              onChange={onChangeFun}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="company_address"
            >
              Address
              <span className="text-red-600"> *</span>
            </label>
            <textarea
              name="company_address"
              onChange={onChangeFun}
              className="w-full h-[164px]  bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] font-[400]  "
              rows={100}
              cols={50}
              required
              id="company_address"
            ></textarea>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="footer_text"
            >
              Footer Text
              <span className="text-red-600"> *</span>
            </label>
            <input
              id="footer_text"
              name="footer_text"
              onChange={onChangeFun}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>

          {/* =============input type-file============ */}
          <div className="flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="app_logo"
            >
              Logo
              <span className="text-red-600"> *</span>
            </label>
            <input
              id="app_logo"
              onChange={handleFilechange}
              name="app_logo"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="file"
              required
            />
          </div>

          <div className="flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="app_favicon"
            >
              Favicon
              <span className="text-red-600"> *</span>
            </label>
            <input
              id="app_favicon"
              onChange={handleFilechange}
              name="app_favicon"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="file"
              required
            />
          </div>
          {/* ====================================================*/}

          <div className="flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="country"
            >
              Country
              <span className="text-red-600"> *</span>
            </label>
            <select
              id="country"
              name="country"
              onChange={onChangeFun}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
              value={generalForm.country}
              required
            >
              <option value={generalForm.company}>{generalForm.country}</option>
                {countries?.data?.map((val,index)=>(
                  <option key={index} value={val?.name} >{val?.name}</option>
                ))}

            </select>
            
          </div>

          <div className="flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="timezone"
            >
              Timezone
              <span className="text-red-600"> *</span>
            </label>
            
            <TimeZonePicker
              data={generalForm.timezone}
              getTimeZone={getTimeZone}
            />
          </div>

          <div className="flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="date_format"
            >
              Date Format
              <span className="text-red-600"> *</span>
            </label>
            <select
              id="date_format"
              name="date_format"
              onChange={onChangeFun}
              required
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
            >
              <option value="jan">United States</option>
              <option value="feb">Nepal</option>
              <option value="july">United States</option>
            </select>
          </div>

          <div className="flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor="language"
            >
              Default Language
              <span className="text-red-600"> *</span>
            </label>
            <select
              id="language"
              name="language"
              onChange={onChangeFun}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400] "
              required
            >
              {/* <option value="nepali">nepali</option>
                  <option value="english">english</option>
                  <option value="arabic">arabic</option> */}
              {settingsData?.language?.map((val, ind) => {
                return (
                  <option key={ind} value={val.code}>
                    {val.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Copy and Paste your custom scripts in the box below. it will be
              included in every page
              <span className="text-red-600"> *</span>
            </label>
            <textarea
              className="w-full h-[100px]  bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] font-[400]  "
              rows={100}
              cols={50}
              name=""
              id=""
            ></textarea>
          </div>

          <div className="flex justify-end">
            <button onClick={handleGeneralFormSubmit} className=" hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn ">
              <BiSave />
              save
            </button>
          </div>
        </form>
        {/* ==================general-form-1 ended================= */}
      </div>
    </>
  );
};

export default GeneralSection;
