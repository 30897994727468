import React,{useState} from 'react'
import create from "../assets/create.png"
import { Select } from 'antd';

const Newcustomer = () => {

    const [FormData,setFormData] = useState({
        email:"",
        password:"",
        confirmPassword:"",
        firstname:"",
        lastname:"",
        phone:"",
        timezone:"",
        language:"",
        status:"",
        image:""
    })

    const handleChange = (e) => {
       const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const submitForm = ()=>{
        console.log("form submitted.")
    }

    return (
        <div className='bg-white pb-[110px] rounded-[3px]'>
            <div className='mt-[17px] pb-[23px] border-b-[1px] border-[#D9D9D9]'>
                <h2 className='text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[26px] pl-[18.74px]'>New Customers</h2>
            </div>
            <form action="" className='pt-[24px] pl-[19px] max- w-[395px]'>
                <div>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Email *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' placeholder='' onChange={handleChange} name="email" value={FormData.email} />
                </div>

                <div>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Password *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' style={{ width: 380 }} placeholder='' onChange={handleChange} name="password" value={FormData.password} />
                </div>

                <div>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>ConfirmPassword *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' style={{ width: 380 }} placeholder='' onChange={handleChange} name="confirmPassword" value={FormData.confirmPassword} />
                </div>

                <div>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Firstname *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' style={{ width: 380 }} placeholder='' onChange={handleChange} name="firstname" value={FormData.firstname} />
                </div>

                <div>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Lastname *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px] mb-[7px]' style={{ width: 380 }} placeholder='' onChange={handleChange} name="lastname" value={FormData.lastname} />
                </div>

                <div>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Phone *</h3>
                    <input className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]' style={{ width: 380 }} placeholder='' onChange={handleChange} name="phone" value={FormData.phone} />
                </div>

                <div className='mt-[20px]'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Timezone *</h3>
                       <Select
                        defaultValue="(GMT+05:45) Asia Kathmandu"
                        className='w-full mt-[9px] '
                        style={{ width: 380 }}
                        onChange={handleChange}
                        options={[
                            {value:"hi",
                            label:"hi"},
                            {value:"hello",
                            label:"hello"},
                        ]}
                    />
                </div>

                <div className='mt-[20px]'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Language *</h3>
                       <Select
                        defaultValue="English"
                        className='w-full mt-[9px]'
                        style={{ width: 380 }}
                        onChange={handleChange}
                        options={[
                            {value:"hi",
                            label:"hi"},
                            {value:"hello",
                            label:"hello"},
                        ]}
                    />
                </div>

                <div className='mt-[20px]'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Status *</h3>
                       <Select
                        defaultValue="active"
                        className='w-full mt-[9px] '
                        style={{ width: 380 }}
                        onChange={handleChange}
                        options={[
                            {value:"active",
                            label:"Active"},
                            {value:"inactive",
                            label:"Inactive"},
                        ]}
                    />
                </div>

                <div className='mt-[20px]'>
                    <h3 className='text-[#5F6368] text-[14px] leading-[14.52px] font-normal'>Image</h3>
                       <input type='file' accept="image/*" className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]'  style={{ width: 380 }}  onChange={handleChange}
                    />
                    <p className='text-[#229]'>Recommended size: Width 300px X Height 300px</p>
                </div>

                <div className='bg-[#0092D7] text-white w-[102px] h-[40px] flex  items-center justify-center gap-[8px] mt-[30px] float-right rounded-[3px] cursor-pointer'>
                    <img src={create} alt="" />
                    <p onClick={submitForm} className='text-[16px]  font-normal'>Create</p>
                </div>
            </form>
        </div>
    )
}

export default Newcustomer