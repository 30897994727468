import { useState, useEffect } from "react";
import usePostMutation from "../../hooks/usePostMutation";
import { getToken } from "../../utils/tokentUtils";
import { useParams } from "react-router-dom";
import useGetQuery from "../../hooks/useGetQuery";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";
import { RiAccountCircleFill, RiDeleteBinLine } from "react-icons/ri";
import {
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineMinusSm,
  HiOutlinePlus,
  HiOutlineUpload,
} from "react-icons/hi";
import TimeZonePicker from "../../TimeZonePicker";
import { TfiSave } from "react-icons/tfi";

const Account = () => {
  const token = getToken();
  const { customer } = useParams();

  //function for get the data for single customer
  const { getQueryData: customerData } = useGetQuery(
    `/admin/customers/${customer}/show`,
    `/admin/customers/${customer}/show`
  );

  //state of form1
  const [form1, setForm1] = useState({
    _method: "PATCH",
    _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    timezone: "",
    locale: "",
  });

  useEffect(() => {
    setForm1((prevForm1) => ({
      ...prevForm1,
      email: customerData?.customer.email,
      first_name: customerData?.customer.first_name,
      last_name: customerData?.customer?.last_name,
      timezone: customerData?.customer?.timezone,
      locale: customerData?.customer?.locale,
    }));
  }, [customerData]);

  //onchange function to update input field of form1
  const handleForm1Change = (event) => {
    const { name, value } = event.target;
    setForm1((prevForm1) => ({
      ...prevForm1,
      [name]: value,
    }));
  };
  // thsi is callback function for TimeZone component.
  const getTimeZone = (timezone) => {
    setForm1((prevForm1) => ({
      ...prevForm1,
      timezone:timezone,
    }));
  };
  console.log(form1.timezone);

  // for showing and hiding password and confirm password.
  const [showPassword, setShowPassword] = useState<any>(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState<any>(true);

  // call for usePost Mutation hook for better for making post request.
  const { mutate: addUnit } = usePostMutation();
  const { mutate: removeUnit } = usePostMutation();

  // adding Customer Unit.
  const handleAddUnit = (id, unit, validationKey) => {
    addUnit({
      url: `/admin/customers/${id}/add-unit`,
      Data: { add_unit: unit },
      validationKey,
    });
  };

  // removing customer unit.
  const handleRemoveUnit = async (id, unit, validationKey) => {
    removeUnit({
      url: `/admin/customers/${id}/remove-unit`,
      Data: { add_unit: unit },
      validationKey,
    });
  };

  // hendling form1 submit.
  const handleForm1Submit = async (e) => {
    e.preventDefault();
    if (form1.password === form1.password_confirmation) {
      try {
        const response = await axios.post(
          `https://smsvendor.com/api/v1/admin/customers/${customer}`,
          { ...form1 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        showSuccessfulToastAlert(response?.data.message);
      } catch (error) {
        showFailedToastAlert(error);
      }
    } else {
      showFailedToastAlert("your Password did't match Password confirmation");
    }
  };

  return (
    <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
      <div className="top flex gap-4 items-center">
        <div>
          <RiAccountCircleFill size={150} />
        </div>
        <div>
          <p>SMS UNIT : {customerData?.customer.sms_unit} </p>
          <div className="flex gap-4 my-2 flex-wrap">
            <button className="bg-[#F08B38] text-white px-3 py-1 rounded-md flex items-center gap-1 ">
              <HiOutlineUpload />
              Upload
            </button>
            <button
              onClick={() =>
                handleAddUnit(
                  customerData?.customer.uid,
                  10,
                  `/admin/customers/${customer}/show`
                )
              }
              className="bg-[#aff079] text-white px-3 py-1 rounded-md flex items-center gap-1 "
            >
              <HiOutlinePlus />
              AddUnit
            </button>
            <button
              onClick={() =>
                handleRemoveUnit(
                  customerData?.customer.uid,
                  10,
                  `/admin/customers/${customer}/show`
                )
              }
              className="bg-[#F08B38] text-white px-3 py-1 rounded-md flex items-center gap-1 "
            >
              <HiOutlineMinusSm />
              RemoveUnit
            </button>
            <button className="bg-[#EA5455] text-white px-3 py-1 rounded-md flex items-center gap-1 ">
              <RiDeleteBinLine />
              remove
            </button>
          </div>
        </div>
      </div>

      {/* -------form part 1----- */}
      <form action=""  onSubmit={(e)=>handleForm1Submit(e)}>
      <div className="bottom  p-2 flex flex-col md:flex-row flex-wrap">
          <div className="left md:w-[50%] w-[100%] md:pr-2">
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Email<span className="text-red-600"> *</span>
              </label>
              <input
                value={form1.email}
                type="email"
                className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                name="email"
                onChange={handleForm1Change}
              />
            </div>
            <div className="flex flex-col  mb-[16px]  ">
              <div className="relative  flex flex-col">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                  Password<span className="text-red-600"> *</span>
                </label>
                <input
                  value={form1.password}
                  type={showPassword ? "password" : "text"}
                  className="rounded-[0.357rem] relative text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem]"
                  name="password"
                  onChange={handleForm1Change}
                />
                {showPassword ? (
                  <HiOutlineEye
                    className="absolute cursor-pointer first-letter: right-[15px] top-[55%] "
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <HiOutlineEyeOff
                    className="absolute cursor-pointer right-[15px] top-[55%] "
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col mb-[16px]  ">
              <div className="relative flex flex-col">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                  Confirm Password<span className="text-red-600"> *</span>
                </label>
                <input
                  value={form1.password_confirmation}
                  type={showConfirmPassword ? "password" : "text"}
                  className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem] "
                  name="password_confirmation"
                  onChange={handleForm1Change}
                />
                {showConfirmPassword ? (
                  <HiOutlineEye
                    className="absolute cursor-pointer right-[15px] top-[55%] "
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                ) : (
                  <HiOutlineEyeOff
                    className="absolute cursor-pointer right-[15px] top-[55%] "
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="Right md:w-[50%] w-[100%] md:pl-2">
            <div className="right-sub flex  gap-2 mb-[16px] items-center  ">
              <div className="flex flex-col items-center w-[50%]  gap-1">
                <label className="text-[0.857rem] text-[#5e5873]  mb-[0.2857rem] self-start ">
                  First name<span className="text-red-600"> *</span>
                </label>
                <input
                  value={form1?.first_name}
                  type="text"
                  className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                  name="first_name"
                  onChange={handleForm1Change}
                />
              </div>
              <div className="flex flex-col items-center w-[50%] gap-1 text-left">
                <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] self-start ">
                  Last name
                </label>
                <input
                  value={form1?.last_name}
                  type="text"
                  className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                  name="last_name"
                  onChange={handleForm1Change}
                />
              </div>
            </div>
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Timezone<span className="text-red-600"> *</span>
              </label>
              <TimeZonePicker data={form1.timezone} getTimeZone={getTimeZone} />
            </div>
            <div className="flex flex-col mb-[16px] ">
              <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                Language<span className="text-red-600"> *</span>
              </label>
              <select
                name="language"
                onChange={handleForm1Change}
                id=""
                className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
              >
                {customerData?.languages?.map((option, index) => (
                  <option key={index} value={option?.code}>
                    {option?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
          >
            <TfiSave />
            save changes
          </button>
        </div>
      </div>
      </form>
    </div>
  );
};

export default Account;
