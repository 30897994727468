import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import usePostQuery from "../hooks/usePostQuery";

const AddNewSenderId = () => {
  //  state of senderid
  const [addSender, setAddSender] = useState<any>({
    sender_id: "",
    status: "",
    price: "",
    billing_cycle: "",
    currency: "",
    select_customer: "",
  });

  







 
    


  return (
    <>
      <div className="w-[50%] mt-4 p-4 shadow-xl  bg-white rounded-md">
        <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
          Add New Sender ID
        </p>
        <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Sender ID
          </span>{" "}
          is what appears on people's phones to show who the SMS is from. It can
          be your{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Brand Name
          </span>{" "}
          or{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Comapany Name
          </span>
          . Set{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Price
          </span>{" "}
          value{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            0
          </span>{" "}
          for free of cost sender id on SMSVENDOR
        </p>
        <form>
          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Sender ID
              <span className="text-red-600"> *</span>
            </label>
            <input
            placeholder="Required"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
            />
          </div>
          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] form-label-size mb-[0.2875]">
              Status
              <span className="text-red-600"> *</span>
            </label>

            <select className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]">
              <option className="text-gray-700" value="">
                Active
              </option>
              <option className="text-gray-700" value="">
                Payment Required
              </option>
              <option className="text-gray-700" value="">
                Block
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Price
              <span className="text-red-600"> *</span>
            </label>
            <input
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Billing cycle
              <span className="text-red-600"> *</span>
            </label>
            <select className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]">
              <option className="text-gray-700" value="">
                Daily
              </option>
              <option className="text-gray-700" value="">
                Monthly
              </option>
              <option className="text-gray-700" value="">
                Yearly
              </option>
              <option className="text-gray-700" value="">
                Custom
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Select Customer
              <span className="text-red-600"> *</span>
            </label>
            <select className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]">
              <option className="text-gray-700" value="option1">
                Super Admin
              </option>
              {/* {senderForm?.select_customer?.map((val) => 
               (
                  <option className="text-gray-700" value="option1">
                    {val.first_name}({val.last_name})
                  </option>
                )
              )} */}
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Currency
              <span className="text-red-600"> *</span>
            </label>
            <select className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]">
              <option className="text-gray-700" value="option1">
                Euro(EUR)
              </option>
              <option className="text-gray-700" value="option1">
                NPR(NPR)
              </option>
              {/* <option className="text-gray-700" value="option2">
                NPR(NPR)
              </option> */}
              {/* {senderForm?.currency?.map((val) => 
               (
                  <option className="text-gray-700" value="option1">
                    {val.name}({val.code})
                  </option>
                )
              )} */}
            </select>
          </div>

          <div className="flex  gap-2 justify-end">
            <button className=" accent-btn ">
              <BiSave />
              Update
            </button>
            <button className=" flex gap-[0.5rem] items-center cursor-pointer bg-white text-[var(--accent-orange)] border border-[var(--accent-orange)]  mb-[1rem] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] ">
              <FiRefreshCw />
              Reset
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewSenderId;
