import { useEffect, useState } from "react";
import { TfiSave } from "react-icons/tfi";

function SpeedLimit({ customerPlans, formDetail, handleFormSubmit }) {
  //state for speed limit
  const [speedForm, setSpeedForm] = useState({
    sending_limit: "",
    max_process: "",
    sending_quota: "",
    sending_quota_time: "",
    sending_quota_time_unit: "",
  });

  //as we need to set initial value of state  with api data we use useeffect()
  useEffect(() => {
    setSpeedForm((prev) => ({
      ...prev,
      sending_limit: customerPlans?.options?.sending_limit,
      max_process: customerPlans?.options?.max_process,
      sending_quota: customerPlans?.options?.sending_quota,
      sending_quota_time: customerPlans?.options?.sending_quota_time,
      sending_quota_time_unit: customerPlans?.options?.sending_quota_time_unit,
    }));
  }, [customerPlans]);

  //onchange function for selectbox of speedLimit
  const onChangeSelectBox = (e, name) => {
    setSpeedForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  //max process option array
  const maxProc = ["1", "2", "3"];
  return (
    <>
      <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] w-[100%]">
        <form
          onSubmit={(e) =>
            handleFormSubmit(
              e,
              formDetail.formSpeed.url,
              speedForm,
              formDetail.formSpeed._method,
              formDetail.formSpeed._token
            )
          }
        >
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 mt-6">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Speed limit<span className="text-red-600"> *</span>
            </label>
            <select
              name="sending_limit"
              id=""
              value={speedForm?.sending_limit}
              onChange={(e) => onChangeSelectBox(e, "sending_limit")}
              className="rounded-md px-[1rem] text-[--accent-gray] md:w-[50%] w-[100%] outline-[--accent-orange] text-sm  border border-grey-500 h-[40px]"
            >
              <option
                value="unlimited"
                selected={
                  speedForm?.sending_limit === "unlimited" ? true : false
                }
              >
                Unlimited
              </option>
              <option
                value="100_per_minute"
                selected={
                  speedForm?.sending_limit === "100_per_minute" ? true : false
                }
              >
                100 sms per min
              </option>
              <option
                selected={
                  speedForm?.sending_limit === "1000_per_hour" ? true : false
                }
                value="1000_per_hour"
              >
                1,000 sms per hour
              </option>
              <option
                value="10000_per_hour"
                selected={
                  speedForm?.sending_limit === "10000_per_hour" ? true : false
                }
              >
                10,000 sms per hour
              </option>
              <option
                value="50000_per_hour "
                selected={
                  speedForm?.sending_limit === "50000_per_hour" ? true : false
                }
              >
                50,000 sms per hour
              </option>
              <option
                value="10000_per_day"
                selected={
                  speedForm?.sending_limit === "10000_per_day" ? true : false
                }
              >
                10,000 sms per day
              </option>
              <option
                value="100000_per_day"
                selected={
                  speedForm?.sending_limit === "100000_per_day" ? true : false
                }
              >
                1,00,000 sms per day
              </option>
              <option
                value="custom"
                selected={speedForm?.sending_limit === "custom" ? true : false}
              >
                Custom
              </option>
            </select>
          </div>
          <div className="mb-4 md:w-[50%] w-[100%]">
            <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                Ultimate SMS{" "}
              </span>
              supports forking
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                multiple processes
              </span>
              for sending multiple times faster in terms of sms throughput.
              However, it will consume multiple times{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400 px-1">
                more CPU and memory
              </span>{" "}
              as a trade-off.
            </p>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Max number of processes<span className="text-red-600"> *</span>
            </label>
            <select
              name=""
              id=""
              value={speedForm?.max_process}
              onChange={(e) => onChangeSelectBox(e, "max_process")}
              className="rounded-md px-[1rem] text-[--accent-gray] md:w-[50%] w-[100%] outline-[--accent-orange] text-sm  border border-grey-500 h-[40px]"
            >
              {maxProc.map((val) => (
                <option
                  value={val}
                  selected={speedForm.max_process === val ? true : false}
                >
                  {val}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4 mb-6 mx-4">
            <button
              className=" bg-[--accent-orange]  text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center "
              type="submit"
            >
              <TfiSave className="mr-1" />
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SpeedLimit;
