import { TfiSave } from "react-icons/tfi";
// import styles from './EditSendingServer.module.css';

function EditSendingServer() {
  return (
    //main container
    <div className="main  my-[15px] font-sans flex md:flex-row flex-col gap-4 w-[100%]">
      {/*left container*/}
      <div className="left flex flex-col w-[100%] md:w-[28%]   gap-4">
        <div className="bg-[white] shadow-md rounded-md w-[100%]">
          <h3 className="text-md font-[500] text-[--accent-gray] mx-4 my-4">
            Create Your Own Server
          </h3>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Name<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%] "
              value="Sul SMS"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Base URL<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
              value="http://103.233.56.42/broker-api/send"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Success Keyword<span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              className="rounded-md text-sm  border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]  "
              value="success"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              HTTP Request Method<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">GET</option>
              <option value="" selected>
                POST
              </option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Enable json encoded post
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">No</option>
              <option value="">Yes</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Content Type
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">None</option>
              <option value="">application/json</option>
              <option value="">application/x-www-form-urlencoded</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Content Type Accept
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">None</option>
              <option value="">application/json</option>
              <option value="">application/x-www-form-urlencoded</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Character Encoding
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">None</option>
              <option value="">gsm-7</option>
              <option value="">ucs-2</option>
              <option value="">utf-8</option>
              <option value="">utf-16</option>
              <option value="">utf-32</option>
              <option value="">ISO-8859-1</option>
              <option value="">ucs-2be</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Ignore SSL certification verification
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">No</option>
              <option value="" selected>
                Yes
              </option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Authorization<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">Authentication available on parameters</option>
              <option value="">Bearer Token</option>
              <option value="">Basic auth</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Plain<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">Yes</option>
              <option value="">No</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Schedule<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
            >
              <option value="">Yes</option>
              <option value="">No</option>
            </select>
          </div>
        </div>

        <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] w-[100%]">
          <div className="mb-4">
            <h3 className="text-md font-[500] text-[--accent-gray] mx-4 my-4 ">Sending Credit</h3>
            <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
              The configuration setting below allows you to set a limit on sms
              sending speed. For example, to limit sending speed to{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                500 sms every 5 minutes{" "}
              </span>{" "}
              , you can set{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                Sending limit = 500, Time value = 5, and Time unit = minute
              </span>{" "}
              accordingly. If you set{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                SMS Per Single Request
              </span>
              grater than 1 then you must have to select your{" "}
              <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
                Delimiter
              </span>{" "}
              for multiple sms.
            </p>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Sending Credit<span className="text-red-600"> *</span>
            </label>
            <input
              type="number"
              className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              value="60"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Time Base<span className="text-red-600"> *</span>
            </label>
            <input
              type="number"
              className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              value="1"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Time Unit<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%]"
              name=""
              id=""
            >
              <option value="">Minute</option>
              <option value="">Hour</option>
              <option value="">Day</option>
            </select>
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              SMS Per Single Unit<span className="text-red-600"> *</span>
            </label>
            <input
              type="number"
              className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
              value="1"
            />
          </div>
          <div className="width-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Delimiter for multiple sms<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%]"
              name=""
              id=""
            >
              <option value="">, comma(,)</option>
              <option value="">; semicolon(;)</option>
              <option value="">array{}</option>
            </select>
          </div>
        </div>
      </div>

      {/*right container*/}
      <div className="bg-white right shadow-md rounded-md w-[100%] md:w-[72%] h-[805px] flex flex-col gap-4">
        <div className="header w-[100%] mt-4">
          <h2 className="text-md font-[500] text-[--accent-gray] float-left ml-4">
            Query parameters
          </h2>
          <button className=" bg-[#F08B38]  text-white px-[21px] py-[11px] text-sm mr-4 rounded-md flex h-[40px] gap-1 items-center align-center float-right">
            <TfiSave className="mr-1"/>
            Update
          </button>
        </div>
        <div className="tableCont p-4 w-[100%]">
          <div className="table text-center w-[100%]">
            <table className="border-collapse center w-[100%]">
              <thead className="bg-[#e6e6e6] w-[100%] h-[50px] border-b-[1.8px] border-solid border-[#d4cbcb] text-[--accent-gray] text-sm">
                <th className="p-[8px]"></th>
                <th className="p-[8px]">Parameter</th>
                <th className="p-[8px]">Value</th>
                <th className="p-[8px]">Add on Parameter</th>
              </thead>
              <tbody>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[#e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Username/API Key
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className=""></td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Password
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Action
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Source
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Destination
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className=""></td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Message
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className=""></td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Unicode
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Type/Route
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Language
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Custom Value 1
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Custom Value 2
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
                <tr className="hover:bg-[#e6e6e6] h-[55px] cursor-pointer w-[100%] border-b-[1.8px] border-solid border-[e9e6e6]">
                  <td className=" text-[0.857rem]  text-[--accent-gray] mb-[0.2857rem]">
                    Custom Value 3
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <input
                      type="text"
                      className="rounded-md text-sm h-[40px] w-[70%] border border-grey-500  text-[--accent-gray]  outline-[--accent-orange] py-[8px] px-[1rem]"
                    />
                  </td>
                  <td className="">
                    <select
                      name=""
                      id=""
                      className="rounded-md text-[--accent-gray] w-[55%] outline-[--accent-orange] px-1 text-sm  border border-grey-500 h-[40px]"
                    >
                      <option value="">Set Blank</option>
                      <option value="">Add on Parameter</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSendingServer;
