import { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";

const { Option } = Select;

// declared type for timeZones state variables
interface TimeZoneOption {
  value: string;
  label: string;
}

const TimeZonePicker = ({ data, getTimeZone }) => {
  console.log(data);
  const [selectedOption, setSelectedOption] = useState(data);
  const [timeZones, setTimeZones] = useState<TimeZoneOption[]>([]);
  console.log(timeZones);

  // fetch timezones data when component is mounted
  useEffect(() => {
    const fetchTimeZones = () => {
      const timeZones = moment.tz.names().map((tz) => ({
        value: tz,
        label: `(GMT${moment().tz(tz).format("Z")}) ${tz}`,
      }));
      setTimeZones(timeZones);
    };

    fetchTimeZones();
  }, []);


    // to show selected data from options feild to outer div
  const handleChange = (value: string) => {

    setSelectedOption(value);
    console.log(value);
  };

  useEffect(() => {
    getTimeZone(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOption(data);
  }, [data]);


  return (
    <>
      {/* <div className="outer-container relative">
        <div
          onClick={handleDropdown}
          className="bg-white outline-1 hover: outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[1rem] px-[1rem] font-[400]"
        >
          {values}
        </div>
        <div
          className={
            dropDown
              ? "bg-black absolute w-full flex flex-col gap-2  hover:flex  top-[2.1rem] p-4"
              : "bg-black absolute w-full hidden   hover:flex  top-[2.1rem] p-4"
          }
        >
          <input
            className="bg-white w-full outline-1 outline-[var(--accent-orange)] border border-[var(--accent-orange)] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <Select
            value={selectedOption}
            onChange={handleChange}
            className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38]"
          >
            <option value="">Select a timeZone</option>
            {filteredTimeZones.map((timeZone) => (
              <Option
                key={timeZone.value}
                value={timeZone.value}
                style={{ border: "none" }}
              >
                {timeZone.label}
              </Option>
            ))}
          </Select>
        </div>
      </div> */}

      <div>

      <Select
        value={selectedOption}
        onChange={handleChange}
        className="rounded-[0.357rem] w-full text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38]"
      >
        
          <input
            className="bg-white outline-1 w-full outline-[var(--accent-orange)] border border-[var(--accent-orange)] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]"
            type="text"
          />
        

        {timeZones.map((timeZone) => (
          <Option
            key={timeZone.value}
            value={timeZone.value}
            style={{ border: "none" }}
          >
            {timeZone.label}
          </Option>
        ))}
      </Select>
      </div>

    </>
  );
};

export default TimeZonePicker;
