import { useToken } from "antd/es/theme/internal";
import axios from "axios";
import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { showFailedToastAlert, showSuccessfulToastAlert } from "../../utils/toastAlerts";

const PusherSection = () => {
  const token = useToken()
  // state for pusher-forms
  const [pusher, setPusher] = useState<any>({
    app_id: "",
    app_key: "",
    app_secret: "",
    app_cluster: "",
  });

  // handles onchange function for all inputs
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setPusher((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

    // handle pusher-form-submit ======= ERROR saying unauthenticated
    const handlePusherFormSubmit =async(e) =>{
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://smsvendor.com/api/v1/admin/settings/pusher`,
        {...pusher},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data)
      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
    
  }



  

  return (
    <>
      <div className="w-[100%] md:w-[60%] mt-[2rem]  px-4">
        <form action="">
          <div className="">
            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] text-justify leading-[1.5rem] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                If you don't have{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Pusher Account
                </span>{" "}
                already, create a free{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Pusher
                </span>{" "}
                account at{" "}
                <span className=" py-[2px] px-[3px] rounded-sm text-[#f08b38] ">
                  Pusher signup
                </span>{" "}
                signup then{" "}
                <span className=" py-[2px] px-[3px] rounded-sm text-[#f08b38] ">
                  login
                </span>{" "}
                to your dashboard and click on{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  create app
                </span>{" "}
                button. Then insert your{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  app name,cluster
                </span>
                , select{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  jQuery
                </span>{" "}
                for{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  front-end tech
                </span>{" "}
                option and{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  Laravel
                </span>{" "}
                for
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  back-end tech
                </span>{" "}
                option. Finally, go{" "}
                <span className="bg-[#eeeeee] py-[2px] px-[3px] rounded-sm text-[#d8428c] ">
                  App keys
                </span>{" "}
                option and update credentials on SMSVENDOR
              </label>
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                APP ID
                <span className="text-red-600"> *</span>
              </label>
              <input
                name="app_id"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
              />
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Key
                <span className="text-red-600"> *</span>
              </label>
              <input
                name="app_key"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
              />
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Secret
                <span className="text-red-600"> *</span>
              </label>
              <input
                name="app_secret"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
              />
            </div>

            <div className="flex gap-2 mb-6 flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Cluster
                <span className="text-red-600"> *</span>
              </label>
              <input
                name="app_cluster"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
                type="text"
              />
            </div>
          </div>

          <div className="flex justify-end">
            <button onClick={handlePusherFormSubmit} className="hover:shadow-lg ease-out duration-300  hover:shadow-orange-500/50 accent-btn ">
              <BiSave />
              save
            </button>
          </div>
        </form>

        {/* ==================pusher-form-5 ended================= */}
      </div>
    </>
  );
};

export default PusherSection;
