import React from "react";
import {RiLightbulbFlashFill} from "react-icons/ri"

const FormSkeleton = (props) => {

  const {titles} = props
  console.log(titles)

  return (
    <>
      <div className=" h-[550px] w-full overflow-x-hidden">
        <table className="h-full w-full mx-3 overflow-x-hidden">
          <thead className="bg-[#e5f0f7] text-left">
            <tr className="">
              {titles.map((val,index)=>(
                <th className={`text-[12px] font-[500] py-4 ${val==="Name"?"pl-[65px]":"pl-[0px]"} ` } key={index}>{val}</th>
              ))}
              
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 relative">
          {[1,2,3,4,5,6,7,8]
              .map((_, index) => (
                <tr key={index} className="formrowskeleton overflow-y-hidden">
                  <td className="formskeleton px-6 whitespace-nowrap overflow-hidden"> <RiLightbulbFlashFill size={20} className=" text-[#1df669] " /></td>
                  <td className="formskeleton px-6 whitespace-nowrap overflow-hidden"> <RiLightbulbFlashFill size={20} className=" text-[#1df669] " /></td>
                  <td className="formskeleton px-6 whitespace-nowrap overflow-hidden"> <RiLightbulbFlashFill size={20} className="text-[#1df669] " /></td>
                  <td className="formskeleton px-6 whitespace-nowrap overflow-hidden"> <RiLightbulbFlashFill size={20} className=" text-[#1df669] " /> </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FormSkeleton;
