import React,{useEffect,useState} from 'react'
import create from "../assets/create.png"
import { Select } from 'antd';
import { getToken } from '../utils/tokentUtils';
import axios from 'axios';
import { showFailedToastAlert } from '../utils/toastAlerts';

const Newsubscription = () => {

    const [selectCostomer,setCustomer] = useState<any>();
    const [selectPlans,setPlans] = useState<any>();


    useEffect(()=>{
        try {
            const token = getToken();
            let data = JSON.stringify({
              length: 10,
              start: 0,
              orderBy: "id",
              orderDir: "DESC",
              search: "",
            });
      
            let config1 = {
              method: "post",
              maxBodyLength: Infinity,
              url: "https://smsvendor.com/api/v1/admin/customers/search",
              headers: {
                Authorization:`Bearer ${token}`,
                "Content-Type": "application/json",
              },
              data: data,
            };
            // second config for diffrent plans...
            let config2 = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://smsvendor.com/api/v1/admin/plans/search",
                headers: {
                  Authorization:`Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                data: data,
              };
      
              // axios request for getting value for diffrent customer details.
            axios
              .request(config1)
              .then((response) => {
                  //console.log(response.data);
                const data = response?.data;
                if (data?.status === "success") {
                    setCustomer(data.data.data)
                    console.log(data.data.data)
                } else {
                  showFailedToastAlert(
                    `Getting subscription data error ${data?.message}`
                  );
                }
              })
              .catch((error) => {
                console.log(`Getting Customers Number error ${error?.message}`);
              });

              // axios request for getting diffrent plans details
              axios
              .request(config2)
              .then((response) => {
                  //console.log(response.data);
                const data = response?.data;
                if (data?.status === "success") {
                    setPlans(data.data.data)
                    console.log(data.data.data)
                } else {
                  showFailedToastAlert(
                    `Getting plans data error ${data?.message}`
                  );
                }
              })
              .catch((error) => {
                console.log(`Getting Customers Number error ${error?.message}`);
              });
          } catch (error) {}
    },[])

    const submitForm = ()=>{
        console.log("form submitted...")
    }


    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    return (
        <div className='bg-white pb-[110px] rounded-[3px]'>
            <div className='mt-[17px] pb-[23px] border-b-[1px] border-[#D9D9D9]'>
                <h2 className='text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[26px] pl-[18.74px]'>New Subscription</h2>
            </div>
            <form action="" className='pt-[24px] pl-[19px] max- w-[395px]'>
                <div>
                    <h3 className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>Select Customer *</h3>
                    {/* <select name="" id="" className='border border-[#D9D9D9] w-full pt-[11px] pl-[11px] pb-[12px]  outline-none mt-[9px]'>
                        <option value="" className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal '>Customer App</option>
                        <option value="" className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>Customer App</option>
                    </select> */}
                    <Select
                        defaultValue="Customer App"
                        className='w-full mt-[9px] '
                        style={{ width: 380 }}
                        onChange={handleChange}
                        options={selectCostomer?.map((val)=>({
                            value:val.name,
                            label:val.name,
                        }))}
                    />
                </div>
                <div className='mt-[20px]'>
                    <h3 className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>Select Plan *</h3>
                    {/* <select name="" id="" className='border border-[#D9D9D9] w-full pt-[11px] pl-[11px] pb-[12px] mt-[9px] outline-none'>
                        <option value="" className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>IC-pro</option>
                        <option value="" className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>IC-pro</option>
                    </select> */}
                       <Select
                        defaultValue="IC-pro"
                        className='w-full mt-[9px] '
                        style={{ width: 380 }}
                        onChange={handleChange}
                        options={selectPlans?.map((val)=>({
                            value:val.plan_name,
                            label:`${val.plan_name} ${val.price}`,
                        }))}
                    />
                </div>
                <div className='mt-[20px]'>
                    <h3 className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>Send Notifications (x) number of days expiration.</h3>
                    {/* <select name="" id="" className='border border-[#D9D9D9] w-full pt-[11px] mt-[9px] pl-[11px] pb-[12px] outline-none'>
                        <option value="" className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>10</option>
                        <option value="" className='text-[#5F6368] text-[12px] leading-[14.52px] font-normal'>10</option>
                    </select> */}
                       <Select
                        defaultValue="10"
                        className='w-full mt-[9px] '
                        style={{ width: 380 }}
                        onChange={handleChange}
                        options={[5,6,7,8,9,10].map((val)=>({
                            value:val,
                            label:val,
                        }))}
                    />
                </div>
                <div className='bg-[#0092D7] text-white w-[102px] h-[34px] flex  items-center justify-center gap-[8px] mt-[30px] float-right rounded-[3px] cursor-pointer'>
                    <img src={create} alt="" />
                    <p onClick={submitForm} className='text-[12px] leading-[14px] font-normal'>Create</p>
                </div>
            </form>
        </div>
    )
}

export default Newsubscription