import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";

const CreateNewKeyword = () => {
  //state for  create new Keywords
  const [keywordForm, setKeywordForm] = useState({
    title: "",
    keyword_name: "",
    user_id: "",
    sender_id: "",
    price: "",
    billing_cycle: "",
    status: "",
    reply_text: "",
    reply_mms: "",
    currency_id: "",
  });

  //onchange function for input
  const onChangeFun = (e, name) => {
    setKeywordForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  //onchange function for selectbox
  const onChangeSelectBox = (e, name) => {
    setKeywordForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  console.log(keywordForm)


  return (
    <>
      <div className="w-[50%] my-4 p-4 shadow-xl  bg-white rounded-md">
        <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
          Create New Keyword
        </p>
        <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
          A{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            Keyword
          </span>{" "}
          makes it easy for subscribers to{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            opt in
          </span>{" "}
          to receive important
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            messages and offers
          </span>{" "}
          . There are no forms to fill out, apps to download, or URLs to
          remember. All they need to do is text your keyword to your number, and
          they're in. You can assign an{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            unique keyword
          </span>{" "}
          to a{" "}
          <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
            unique user
          </span>{" "}
          on SMSVENDOR
        </p>
        <form>
          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Title
              <span className="text-red-600"> *</span>
            </label>
            <input
                value={keywordForm?.title}
                onChange={(e) => onChangeFun(e, "title")}
              placeholder="Required"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
              htmlFor=""
            >
              Keyword
              <span className="text-red-600"> *</span>
            </label>

            <input
                value={keywordForm?.keyword_name}
                onChange={(e) => onChangeFun(e, "keyword")}
              placeholder="Required"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="text"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Sender ID
            </label>
            <input
                value={keywordForm?.sender_id}
                onChange={(e) => onChangeFun(e, "sender_id")}
              placeholder="Required"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="number"
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Reply Text For Recipient
              <span className="text-red-600"> *</span>
            </label>
            <textarea
                value={keywordForm?.reply_text}
                onChange={(e) => onChangeFun(e, "reply_text")}
              className="w-full h-[100px]  bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] font-[400]  "
              rows={100}
              cols={50}
            ></textarea>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Reply Voice For Recipient
              <span className="text-red-600"> *</span>
            </label>
            <textarea
                value={keywordForm?.reply_mms}
                onChange={(e) => onChangeFun(e, "reply_mms")}
              className="w-full h-[100px]  bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] font-[400]  "
              rows={100}
              cols={50}
            ></textarea>
          </div>

          <div className="flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Reply MMS For Recipient
              <span className="text-red-600"> *</span>
            </label>
            <input
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block text-[1rem] py-[0.571rem] px-[1rem] font-[400]  "
              type="file"
            />

            {/* <input type="file" name="" id="" /> */}
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
              htmlFor=""
            >
              Status
              <span className="text-red-600"> *</span>
            </label>

            <select
                value={keywordForm?.status}
                onChange={(e) => onChangeSelectBox(e, "status")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="Available">
                Available
              </option>
              <option className="text-gray-700" value="Assigned">
                Assigned
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
              htmlFor=""
            >
              Select Customer
              <span className="text-red-600"> *</span>
            </label>
            <select
                onChange={(e) => onChangeSelectBox(e, "user_id")}
                value={keywordForm.user_id}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              {/* <option className="text-gray-700" value="option1">
                {senderForm?.select_customer}
              </option> */}
              {/* <option value="">None</option> */}
              <option value="1">Super Admin</option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Price
              <span className="text-red-600"> *</span>
            </label>
            <input
                value={keywordForm?.price}
                onChange={(e) => onChangeFun(e, "price")}
              placeholder="Required"
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
              type="number"
              required
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Currency
              <span className="text-red-600"> *</span>
            </label>
            <select
                onChange={(e) => onChangeSelectBox(e, "currency_id")}
                value={keywordForm.currency_id}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="2">
                Euro(EUR)
              </option>
              <option className="text-gray-700" value="12">
                NPR(NPR)
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] ">
              Renew
              <span className="text-red-600"> *</span>
            </label>
            <select
                value={keywordForm?.billing_cycle}
                onChange={(e) => onChangeSelectBox(e, "billing_cycle")}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              required
            >
              <option className="text-gray-700" value="Daily">
                Daily
              </option>
              <option className="text-gray-700" value="Monthly">
                Monthly
              </option>
              <option className="text-gray-700" value="Yearly">
                Yearly
              </option>
              <option className="text-gray-700" value="Custom">
                Custom
              </option>
            </select>
          </div>

          <div className="flex  gap-2 justify-end">
            <button className=" accent-btn ">
              <BiSave />
              Save
            </button>
            <button className=" flex gap-[0.5rem] items-center cursor-pointer bg-white text-[var(--accent-orange)] border border-[var(--accent-orange)]  mb-[1rem] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] ">
              <FiRefreshCw />
              Reset
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateNewKeyword;
