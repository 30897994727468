import { useEffect, useState } from "react";
import { getToken } from "../../utils/tokentUtils";
import { useParams } from "react-router-dom";
import useGetQuery from "../../hooks/useGetQuery";
import axios from "axios";
import { TfiSave } from "react-icons/tfi";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../utils/toastAlerts";

const Permission = () => {
  const token = getToken();
  const { customer } = useParams();

  //function for get the data for single customer
  const { getQueryData: customerData } = useGetQuery(
    `/admin/customers/${customer}/show`,
    `/admin/customers/${customer}/show`
  );

  //state of form3
  const [form3, setForm3] = useState(() => {
    const state = {};
    if (customerData?.permissions) {
      customerData?.permissions.forEach((val) => {
        if (val.permissions) {
          val.permissions.forEach((value) => {
            state[value.display_name] = value.default;
          });
        }
      });
    }
    return state;
  });

  useEffect(() => {
    const newState = {};
    if (customerData?.permissions) {
      customerData.permissions.forEach((val) => {
        if (val.permissions) {
          val.permissions.forEach((value) => {
            newState[value.display_name] = value.default;
          });
        }
      });
    }
    setForm3(newState);
  }, [customerData]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setForm3((prevForm3) => ({
      ...prevForm3,
      [name]: checked,
    }));
  };

  //creating array state that is required to save checked items
  const [checkedItems, setCheckedItems] = useState<any>([]);

  //need to push checked item of the form in an array as we need all checked item to pass in api
  const pushCheckItem = (form) => {
    //this will change object into array of object which will contain key and value from object
    const arrOfObj = Object.keys(form).map((key) => ({
      key,
      value: form[key],
    }));

    //newarray is uded so that we can use push()
    const newArray = [checkedItems];

    //checking if key has value true than push that key to new array
    arrOfObj.map((val) => {
      if (val.value) {
        newArray.push(val.key);
      }
    });

    //finally newarray is passed to checkeditems
    setCheckedItems(newArray);
  };

  //it will update check_items on update on form3
  useEffect(() => {
    pushCheckItem(form3);
  }, [form3]);

  const handleForm3Submit = async () => {
    try {
      const response = await axios.post(
        `https://smsvendor.com/api/v1/admin/customers/${customer}/permissions`,
        {
          _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
          permissions: checkedItems,
          "permissions[access_backend]": "access_backend",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
    console.log(customerData);
    console.log(form3);
    console.log(checkedItems);
  };

  return (
    <div className="top-bottom-container rounded-md shadow-xl bg-white p-4 my-2">
      {/* -------form part 3----- */}
      <div className="bottom  p-2">
        <form action="" className="">
          {customerData?.permissions?.map((value, index) => (
            <div key={index} className="mb-[2rem]">
              <div className="flex items-center mb-4 w-full">
                <h3 className="mr-2 text-[#8E85F3] font-[600]">
                  {value?.title}
                </h3>
                <div className="h-[1px] flex-1 bg-[#17D3EA]"></div>
              </div>

              <div className="flex flex-wrap gap-2">
                {value.permissions.map((val, index) => (
                  <div key={index} className="flex mt-2 gap-2">
                    <input
                      className="cursor-pointer h-5 w-4 "
                      type="checkbox"
                      checked={form3[val.display_name]}
                      onChange={handleCheckboxChange}
                      name={val.display_name}
                    />
                    <label
                      className="text-[#5f6368] text-[14px] font-thin"
                      htmlFor={`${val.display_name}`}
                    >
                      {val.display_name.toUpperCase()}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </form>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleForm3Submit}
            className="bg-[#F08B38]  text-white px-[21px] py-[11px] rounded-md flex gap-1  items-center"
          >
            <TfiSave />
            save changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Permission;
