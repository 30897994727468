import React, {useState} from 'react'
import create from "../assets/create.png"

const Addnewplan = () => {

    const [formData, setFormData] = useState({
        name: '',
        price: Number,
        currency_id:Number,
        billingCycle: '',
        frequency_amount: '',
        frequency_unit:Number
      });

      const handleChange = event => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
      };

    const CreateNewPlan = ()=>{

    }

    return (
        <div className='bg-white pb-[22px]'>
            <div className='mt-[28px] border-b-[1px] border-[#D9D9D9]'>
                <h2 className='text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[15px] pl-[13.74px] pb-[23px]'>Add a New Plan</h2>
            </div>
            {/* -------------------------------------------------------------- */}
            <div className='grid grid-cols-2 gap-[53px]'>
                <div className='pt-[47px] pl-[31px]'>
                    <form action="">
                        <div>
                            <h2 className='text-[#545454] text-[13px] leading-[15.23px] mb-[4px]'>Name *</h2>
                            <input name="name" value={formData.name} onChange={handleChange}  type="text" className='border border-[#D9D9D9] rounded-[1px] w-full h-[38px] outline-none pl-[14px]' />
                        </div>
                        <div className='mt-[24px]'>
                            <h2 className='text-[#545454] text-[13px] leading-[15.23px] mb-[4px]'>Price *</h2>
                            <input name="price" value={Number(formData.price)} onChange={handleChange} type="number" placeholder='password' className='border border-[#D9D9D9] rounded-[1px] w-full  h-[38px] pl-[14px] outline-none' />
                        </div>
                        <div className='grid grid-cols-2 gap-[24px] mt-[31px]'>
                            <div>
                                <h2 className='text-[#545454] text-[13px] leading-[15.23px] mb-[4px]'>Billing Cycle</h2>
                                <input name="billingCycle" value={formData.billingCycle} onChange={handleChange} type="text" className='border border-[#D9D9D9] rounded-[1px] w-full outline-none  h-[38px] pl-[14px]' />
                            </div>
                            <div>
                                <h2 className='text-[#545454] text-[13px] leading-[15.23px] mb-[4px]'>Currency</h2>
                                <input  name="currency_id" value={Number(formData.currency_id)} onChange={handleChange} type="number" className='border border-[#D9D9D9] rounded-[1px] w-full outline-none  h-[38px] pl-[14px]' />
                            </div>
                        </div>
                        <div className='flex items-center gap-[9.21px] mt-[15px]'>
                            <input  type="checkbox" className='w-[11px] h-[11px] border-[#5F6368] cursor-pointer outline-none' />
                            <p className='text-[#5F6368] text-[12px] leading-[14.06px] font-normal'>Shows in Customers.</p>
                        </div>
                        <div className='flex items-center gap-[9.21px] mt-[18px] mb-[18px]'>
                            <input type="checkbox" className='w-[11px] h-[11px] border-[#5F6368] cursor-pointer outline-none'/>
                            <div>
                                <h2 className='text-[#5F6368] text-[12px] leading-[14.06px] font-normal'>Billing Information required ?</h2>
                                <p className='text-[#5F6368] text-[10px] leading-[11.72px] font-normal'>Ask for tax billing information when subscribing to the plan</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-[9.21px]'>
                            <input type="checkbox" className='w-[11px] h-[11px] border-[#5F6368] cursor-pointer' />
                            <div>
                                <h2 className='text-[#5F6368] text-[12px] leading-[14.06px] font-normal'>Set as popular ?</h2>
                                <p className='text-[#5F6368] text-[10px] leading-[11.72px] font-normal'>Set this plan as a popular plan</p>
                            </div>
                        </div>
                        <div onClick={CreateNewPlan} className='bg-[#0092D7] text-white w-[102px] h-[34px] flex  items-center justify-center gap-[8px] mt-[30px] float-right rounded-[3px] cursor-pointer'>
                            <img src={create} alt="" />
                            <p className='text-[12px] leading-[14px] font-normal'>Create</p>
                        </div>
                    </form>
                </div>
                <div className='pt-[59px]'>
                    <h2 className='text-[#0092D7] text-[13px] leading-[15.23px] font-bold mb-[10px]'>What is a plan ?</h2>
                    <p className='text-[#5F6368] text-[12px] leading-[19.2px] font-normal w-[423px]'>A plan, or service plan, is the group of services and limits that you have agreed to provide to your customer. A plan allows you to centrally plan and standardize your service offerings. Set up your plan details.</p>
                </div>
            </div>
        </div>
    )
}

export default Addnewplan