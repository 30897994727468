import axios from 'axios';
import React,{useEffect,useState} from 'react'
import { showFailedToastAlert } from '../utils/toastAlerts';
import { getToken } from '../utils/tokentUtils';
import { useParams } from 'react-router-dom';

const SubscriptionLogs = () => {

        const { subscription } = useParams();


    useEffect(() => {
        try {
          const token = getToken();
          let data = JSON.stringify({
            length: 10,
            start: 0,
            orderBy: "id",
            orderDir: "DESC",
            search: "",
          });
    
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://smsvendor.com/api/v1/admin/subscriptions/${subscription}/logs`,
            headers: {
              Authorization:`Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
    
          axios
            .request(config)
            .then((response) => {
              //   console.log(response.data);
              const data = response?.data;
              if (data?.status === "success") {
                console.log(data)
              } else {
                showFailedToastAlert(
                  `Getting Subscription logs error ${data?.message}`
                );
              }
            })
            .catch((error) => {
              console.log(`Getting Subscription logs error ${error?.message}`);
            });
        } catch (error) {}
      }, []);



  return (
    <div>SubscriptionLogs</div>
  )
}

export default SubscriptionLogs