import {useParams} from "react-router-dom";
// import {useNavigate} from "react-router-dom";
import useGetQuery from "../hooks/useGetQuery";
import { TfiSave } from "react-icons/tfi";
import { useEffect, useState } from "react";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import { getToken } from "../utils/tokentUtils";
import axios from "axios";
import { apiBaseUrl } from "../utils/urls";

function AddCoverage() {
  const { plan } = useParams();
  const token = getToken();

  //using useNavigate hooks to go back to prev page
  // const navigate = useNavigate()

  //calling get api for addCoverage
  const { getQueryData: coverageData } = useGetQuery(
    `/admin/plans/${plan}/coverage`,
    `/admin/plans/${plan}/coverage`
  );

  //state of coverageForm
  const [coverageForm, setCoverageForm] = useState({
    _token: "qCFFUvaNEIdJmEEaSMX3YWAs1g6Gvzxour333TrL",
    country: "",
    plain_sms: "",
    receive_plain_sms: "",
    voice_sms: "",
    receive_voice_sms: "",
    mms_sms: "",
    receive_mms_sms: "",
    whatsapp_sms: "",
    receive_whatsapp_sms: "",
  });

  useEffect(() => {
    setCoverageForm((prev) => ({
      ...prev,
      country: coverageData?.countries[0].id,
    }));
  }, [coverageData]);

  //onchange function for coverage state
  const onChangeFun = (e, name) => {
    setCoverageForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };
  //onchange function for selectbox of coverage
  const onChangeSelectBox = (e, name) => {
    setCoverageForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  //handle on submit form for coverage
  const handleFormSubmit = async (e, data) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiBaseUrl}/admin/plans/${plan}/coverage`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
      console.log(data)
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  //Form items
  const formItem = [
    "plain_sms",
    "receive_plain_sms",
    "voice_sms",
    "receive_voice_sms",
    "mms_sms",
    "receive_mms_sms",
    "whatsapp_sms",
    "receive_whatsapp_sms",
  ];
  return (
    <div className="mb-6 font-sans">
      <div className="bg-[white] shadow-md rounded-md  flex flex-col space-around-[20px] md:w-[50%] w-[100%] mt-6">
        <div className="mb-4">
          <h3 className="text-md font-[500] text-[--accent-gray] mx-4 my-4 ">
            Add Coverage
          </h3>
          <p className="text-[0.857rem] mx-4  text-[--accent-gray] mb-[0.2857rem]">
            Please use only{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
              numeric
            </span>{" "}
            number not{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
              decimal
            </span>{" "}
            number. Example: Accepted:{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
              1, 2, 3
            </span>{" "}
            Not Accepted:{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
              0.025, 1.025, 1.00, 1.5
            </span>{" "}
            You can set different price for different sms types.{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">e.g.</span>{" "}
            Receiving 1 plain or normal text and replying to 1 plain or normal
            text will deduct{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">2 SMS</span>
            messages from customer sms credit balance{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
              1 credit for receive sms and 1 credit for send sms.
            </span>{" "}
            Also Receiving 1 voice text and replying to 1 voice text will deduct{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">3 SMS</span>{" "}
            messages from customer sms credit balance{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
              1 credit for receive sms and 2 credit for send sms.
            </span>{" "}
            You can also set{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">MMS</span>{" "}
            and{" "}
            <span className="rounded-sm bg-[#e6e6e6] text-pink-400">
              WhatsApp
            </span>{" "}
            sms price accordingly.
          </p>
        </div>
        <form onSubmit={(e) => handleFormSubmit(e, coverageForm)}>
          <div className="w-[100%] flex flex-col mb-[1rem] mx-4 ">
            <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
              Country<span className="text-red-600"> *</span>
            </label>
            <select
              className="rounded-md text-[--accent-gray] px-[1rem] outline-[--accent-orange] text-sm  border border-grey-500 h-[30px] w-[80%] "
              name=""
              id=""
              value={coverageForm.country}
              onChange={(e) => onChangeSelectBox(e, "country")}
            >
              {coverageData?.countries.map((val, key) => (
                <option value={val?.id}>{`${val?.name} (+${val?.country_code})`}</option>
              ))}
            </select>
          </div>

          <div className="w-[100%] flex  mb-[1rem] mx-4 flex-wrap gap-5">
            {formItem.map((val, key) => {
              return (
                <div
                  className="w-[42%] flex flex-col "
                  key={key}
                  onChange={(e) => onChangeFun(e, val)}
                >
                  <label className="text-[0.857rem] w-[100%] text-[--accent-gray] mb-[0.2857rem]">
                    {val.charAt(0).toUpperCase()+val.slice(1)}
                    <span className="text-red-600"> *</span>
                  </label>
                  <input
                    type="number"
                    className="rounded-md text-sm  border border-grey-500  text-[#6e6b7b]  outline-[--accent-orange] py-[8px] px-[1rem] h-[30px] w-[80%]"
                    required
                  />
                </div>
              );
            })}
          </div>
          <div className="mt-4 mb-6 ml-4 ">
            <button
              type="submit"
              className=" bg-[--accent-orange] text-white px-[21px] py-[11px] text-md mr-4 rounded-md flex h-[40px] items-center align-center "
            >
              <TfiSave className="mr-1" />
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCoverage;
