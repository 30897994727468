import React, { useState } from "react";
import { Switch } from "antd";
import { TfiSave } from "react-icons/tfi";

export const ThemeCustomizer = () => {


  // state for theme-Customizers
  const [theme, setTheme] = useState<any>({
    menu_layout: "",
    skin: "",
    nav_color: "",
    nav_type: "",
    footer_type: "",
    layout_width: "",
    menu_collapsed: null,
    show_breadcrumbs: null,
  });

  // Handle onchange function for inputs fields
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setTheme((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  // onchange handler for switchs
  const onChange = (name,checked: boolean) => {
    console.log(checked);
    setTheme((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };


  console.log(theme);

  return (
    <>
      <div className="w-[50%] mt-4  bg-white rounded-md">
        <p className="title w-full  p-4 text-[1.285rem] font-[500] text-[var(--accent-gray)] border-b-[1px] border-[var(--accent-gray)] ">
          Theme Customizer
        </p>

        {/* ----------form container------------ */}
        <div className="form-container p-[21px] ">
          <form>
            {/* -------menu layout input field-------- */}
            <div className=" mb-4 gap-1 flex flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875] "
                htmlFor=""
              >
                Menu Layout
                <span className="text-red-600"> *</span>
              </label>

              <select
                name="menu_layout"
                onChange={onChangeFun}
                className=" bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              >
                <option className="text-gray-700" value="vertical">
                  Vertical
                </option>
                <option className="text-gray-700" value="horizontal">
                  Horizontal
                </option>
              </select>
            </div>

            {/* -------skin input field-------- */}
            <div className=" mb-4 gap-1 flex flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                htmlFor=""
              >
                Skin
                <span className="text-red-600"> *</span>
              </label>

              <select
                name="skin"
                onChange={onChangeFun}
                className=" bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              >
                <option className="text-gray-700" value="light">
                  Light
                </option>
                <option className="text-gray-700" value="bordered">
                  Bordered
                </option>
                <option className="text-gray-700" value="dark">
                  Dark
                </option>
                <option className="text-gray-700" value="semi_dark">
                  Semi Dark
                </option>
              </select>
            </div>

            <div className=" mb-4 gap-1 flex flex-col">
              <label className="text-[var(--accent-gray)] form-label-size mb-[0.2875]">
                Navbar Color
                <span className="text-red-600"> *</span>
              </label>

              <select
                name="nav_color"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              >
                <option className="text-gray-700" value="purple">
                  purple
                </option>
                <option className="text-gray-700" value="blue">
                  Blue
                </option>
                <option className="text-gray-700" value="orange">
                  Orange
                </option>
                <option className="text-gray-700" value="green">
                  Green
                </option>
                <option className="text-gray-700" value="red">
                  Red
                </option>
                <option className="text-gray-700" value="dark">
                  Dark
                </option>
                <option className="text-gray-700" value="white">
                  White
                </option>
                <option className="text-gray-700" value="gray">
                  Gray
                </option>
              </select>
            </div>

            <div className=" mb-4 gap-1 flex flex-col">
              <label className="text-[var(--accent-gray)] form-label-size mb-[0.2875]">
                Navbar Type
                <span className="text-red-600"> *</span>
              </label>

              <select
                name="nav_type"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              >
                <option className="text-gray-700" value="floating">
                  Floating
                </option>
                <option className="text-gray-700" value="static">
                  Static
                </option>
                <option className="text-gray-700" value="sticky">
                  Sticky
                </option>
              </select>
            </div>

            <div className=" mb-4 gap-1 flex flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                htmlFor=""
              >
                Footer Type
                <span className="text-red-600"> *</span>
              </label>
              <select
                name="footer_type"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              >
                <option className="text-gray-700" value="static">
                  Static
                </option>
                <option className="text-gray-700" value="sticky">
                  Sticky
                </option>
                <option className="text-gray-700" value="hidden">
                  Hidden
                </option>
              </select>
            </div>

            <div className=" mb-4 gap-1 flex flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                htmlFor=""
              >
                {" "}
                Layout Width <span className="text-red-600"> *</span>
              </label>
              <select
                name="layout_width"
                onChange={onChangeFun}
                className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
              >
                <option className="text-gray-700" value="full_width">
                  Full Width
                </option>
                <option className="text-gray-700" value="boxed">
                  Boxed
                </option>
              </select>
            </div>

            {/* ---switch input one-- */}
            <div className="switch-container gap-[16px]  flex flex-col">
              <label
                className="text-[var(--accent-gray)]  mt-[1rem] form-label-size mb-[0.2875]"
                htmlFor=""
              >
                Menu Collapsed
              </label>
              <Switch
                className="w-[10px] checked:bg-orange-500 "
                checked={theme.menu_collapsed}
                onChange={(checked)=>onChange('menu_collapsed',checked)}
                style={{
                  backgroundColor: theme.menu_collapsed ? "#f08b38" : "#E2E2E2",
                }}
              />
              <p className="text-red-600 text-[14px] ">
                Warning:this option only applies to the vertical layout
              </p>
            </div>

            {/* ---switch input two-- */}
            <div className="switch-container my-4 gap-[1rem] flex flex-col">
              <label
                className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
                htmlFor=""
              >
                show Breadcrumbs
              </label>
              <Switch
                className="w-[10px] bg-[#f08b38] "
                checked={theme.show_breadcrumbs}
                // onChange={onChange2}
                onChange={(checked)=>onChange('show_breadcrumbs',checked)}
                style={{
                  backgroundColor: theme.show_breadcrumbs
                    ? "#f08b38"
                    : "#E2E2E2",
                }}
              />
            </div>

            <div className="c-btn  flex justify-end mt-4">
              <button className="accent-btn">
                <TfiSave />
                save
              </button>
            </div>
          </form>
        </div>
        {/* -----------form container ended---------- */}
      </div>
    </>
  );
};
