import React, {useState, useEffect} from 'react'
import importdata from "../assets/importdata.png"
import bulkaction from "../assets/bulkaction.png"
import addnew from "../assets/addnew.png"
import search from "../assets/search.png"
import DataTable from 'react-data-table-component';
import { Switch } from 'antd';
import { showFailedToastAlert } from '../utils/toastAlerts'
import axios from 'axios'
import { getToken } from '../utils/tokentUtils'
import { NavLink } from 'react-router-dom'
import FormSkeleton from './FormSkeleton'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import HandlingPagination from './HandlingPagination'


const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };


const columns: any = [
    {
        name: 'Name',
        selector: row => row.name,
    },
    {
        name: 'Type',
        selector: row => <div className='bg-[#0092D7] w-[40px] h-[17px] rounded-[5px] flex items-center justify-center cursor-pointer'><p className='text-[11px] font-normal leading-[13px] text-white'>{row.type}</p></div>,
    },
    {
        name: 'Quota',
        selector:row=>row.quota
        // cell: row => <> <label className="switch">
        //     <input type="checkbox" checked />
        //     <span className="slider round"></span>
        // </label></>
    },
    {
        name: 'Status',
        selector: row =>  <Switch defaultChecked checked={row.status} onChange={onChange} />,
    },
    {
        name: 'Action',
        selector: row => <div className='flex gap-4 justify-start '>
         <NavLink to="/admin/customers/edit" ><AiOutlineDelete className='text-[#EA5455] cursor-pointer ' size={25} /></NavLink> 
         <NavLink to={`/admin/sending-servers/${row.id}/edit`}><FiEdit className='text-[#7367F0] cursor-pointer' size={20} /></NavLink> 
        </div>,
    },
];

const SendingServers = () => {

    const [searchinput,setSerchInput] = useState<any>();
    const [sendingServer,setSendingServer] = useState<any>(null);

    useEffect(() => {
        const fun =()=>{
            try {
                const token = getToken();
                let data = JSON.stringify({
                  length: 10,
                  start: 0,
                  orderBy: "id",
                  orderDir: "DESC",
                  search: "",
                });
          
                let config = {
                  method: "post",
                  maxBodyLength: Infinity,
                  url: "https://smsvendor.com/api/v1/admin/sending-servers/search",
                  headers: {
                    Authorization:`Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                  data: data,
                };
          
                axios
                  .request(config)
                  .then((response) => {
                    //   console.log(response.data);
                    const data = response?.data;
                    if (data?.status === "success") {
                      setSendingServer(data?.data.data)

                    } else {
                      showFailedToastAlert(
                        `Getting Customers Number error ${data?.message}`
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(`Getting Customers Number error ${error?.message}`);
                  });
              } catch (error) {}
        }
        // only run when app is in devlopment server.
        fun();
      }, []);


        // data that have been returned from pagination.
    const [dataForTable, setTable] = useState<any>();
    const getDataForTable = (paginatedValue)=>{
      setTable(paginatedValue)
    }

      const data = 
      dataForTable?.map((val)=>({
            id: val.uid,
            name: val.name,
            current: 'Ecommerce-API',
            status:val.status,
            type:val.type,
            quota:`Sending Limit ${val.quota_value} Per ${val.quota_base} ${val.quota_unit}`
        }))  
    
    return (
        <div className='bg-white'>
            <div className='mt-[16px] pt-[23px] pb-[12px] items-center flex flex-col md:flex-row gap-[20px] md:gap-0 justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]'>
                <div className='flex items-center'>
                    <h2 className='text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]'>Sending Servers</h2>
                </div>
                <div className='flex gap-[15px]'>
                    <div className='flex items-center bg-[#2AA3001A] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer'>
                        <p className='text-[#2AA300] text-[12px] leading-[14.06px]'>Export Data</p> <img src={importdata} alt="" />
                    </div>
                    <div className='flex items-center bg-[#F1602B1A] gap-[6px] p-[10px] rounded-[3px] cursor-pointer'>
                        <p className='text-[12px] leading-[14.06px] text-[#F1602B]'>Bulk Action</p>
                        <img src={bulkaction} alt="" />
                    </div>
                    <NavLink to="/admin/sending-servers/select">
                    <div className='flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] rounded-[3px] cursor-pointer'>
                        <p className='text-[12px] leading-[14.06px]'>Choose Sending Server</p>
                        <img src={addnew} alt="" />
                    </div>
                    </NavLink>
                </div>
            </div>

            {/* ------------------------------------------------ */}
            <div className='flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]'>
                <div className='flex items-center gap-[8px]'>
                    <p className='text-[#5F6368] text-[13px] leading-[15.23px] font-normal'>Show</p>
                    <select name="num" id="num" className=' border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer'>
                        <option value="10">10</option>
                        <option value="9">9</option>
                        <option value="8">8</option>
                        <option value="7">7</option>
                    </select>
                    <p className='text-[#5F6368] text-[13px] leading-[15.23px] font-normal'>Entries</p>
                </div>
                <div className='border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between'>
                    <input onChange={(e)=>setSerchInput(e.target.value)} type="text" placeholder='Search' className='pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal' />
                    <img src={search} alt="" className='cursor-pointer' />
                </div>
            </div>
            {/* ------------------------------------------------ */}
            
            
            { sendingServer===null ? <FormSkeleton titles={['Name','Type',"Quota","Status","Action"]} />:
            <DataTable
                columns={columns}
                data={data}
                selectableRows
                className='px-[10px] h-[550px]'
            />}


            <HandlingPagination data={sendingServer} getDataForTable={getDataForTable} />

        </div>
    )
}

export default SendingServers